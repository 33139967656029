<template>
  <div class="bg-white p-4 rounded-lg shadow-md w-full md:w-[46%] lg:w-[22%] mb-8 mx-4 relative">
    <ModalChoixBobinePourProduit v-show="showModalBobine" :close-modal="closeModalSelectedBobine" :nb-max-bobine="produit.nombre" :bobines-de-la-couleur="bobinesDeLaCouleurDuProduit" />

    <div class="text-center mb-4">
      <loading-text v-show="!isEditMode" :texte="props.produit.nom" :is-loaded="produit.notLoad" type="h2" text-center="true"/>
      <input v-show="isEditMode" :placeholder="t('inputProductName')" v-model="editName" class="text-xl font-bold text-center" >
    </div>
    <div>
      <loading-text v-show="!isEditMode" :texte="t('colorWithDots') + produit.couleur.libelle" :is-loaded="produit.notLoad" type="span"/>
      <loading-text v-show="!isEditMode" :texte="t('quantityWithDots') + produit.nombre" :is-loaded="produit.notLoad" type="span"/>
      <loading-text v-show="!isEditMode" :texte="t('fillingWithDots') + produit.remplissage + '%'" :is-loaded="produit.notLoad" type="span"
                    :class="{'mb-2': produit.poid === 0 && produit.temp === 0 && !isShowingAdminButton}"/>
      <loading-text v-if="produit.poid !== 0 && !isShowingAdminButton" :texte="t('weightWithDots') + produit.poid" :is-loaded="produit.notLoad" type="span"/>
      <loading-text v-if="produit.temp !== 0 && !isShowingAdminButton" :texte="t('timeWithDots') + produit.temp" :is-loaded="produit.notLoad" type="span"/>
      <loading-text v-if="produit.imprimante && !isShowingAdminButton" :texte="t('printerWithDot') + produit.imprimante.marque + ' ' + produit.imprimante.modele" :is-loaded="produit.notLoad" type="span" class="mb-2"/>
      <etat-chips v-if="!isEditMode" :etat="props.produit.etat" />

      <div v-show="isEditMode" class="flex flex-row gap-2">
        <span>{{t('colorWithDots')}}</span>
        <select v-model="editColor" class="border border-black rounded-lg flex-1">
          <option disabled value="-1">
            {{t("selectAColor")}}
          </option>
          <option v-for="couleur of couleurStore.couleurs" :key="couleur" :value="couleur.uuid">
            {{ couleur.libelle }}
          </option>
        </select>
      </div>

      <div v-show="isEditMode" class="flex flex-row gap-2 mt-2">
        <span>{{t('quantityWithDots')}}</span>
        <input v-model="editQuantity" type="number" minlength="1" class="border border-black rounded-lg flex-1">
      </div>

      <div v-show="isEditMode" class="flex flex-row gap-2 mt-2">
        <span>{{t('fillingWithDots')}}</span>
        <select v-model="editFilling" class="border border-black rounded-lg flex-1">
          <option v-for="i in 11" :key="i-1" :value="(i-1)*10" :selected="(i-1)*10 === 20">{{ (i-1)*10 }}%</option>
        </select>
      </div>

      <div v-show="isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION">
        <span>{{t("weightWithDots")}}</span>
        <input v-model="poidEdit" type="number" minlength="1" class="border border-black rounded-lg px-1">
      </div>
      <div v-show="isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION">
        <span>{{t("timeWithDots")}}</span>
        <input id="tempsEdit" v-on:change="resolveMathTemps" v-model="tempsEdit" minlength="1" class="border border-black rounded-lg px-1">
      </div>
      <div v-show="isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION">
        <span>{{t("coilWithDots")}}</span>
        <button @click="() => {showModalBobine = true}" class="text-blue-500 mt-1">{{t("selectCoil")}}</button>
      </div>

      <div v-show="isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION">
        <span>{{t("printerWithDot")}}</span>
        <select v-model="printerUsed" class="border border-black rounded-lg">
          <option selected disabled value="-1">
            {{t("chooseAPrinter")}}
          </option>
          <option v-for="imprimante of imprimanteStore.imprimantes" :key="imprimante" :value="imprimante.uuid">
            {{ imprimante.marque }} {{ imprimante.modele }}
          </option>
        </select>
      </div>

      <div v-show="isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION" class="w-full flex flex-row justify-center">
        <button @click="showCreateFailedModal(produit.uuid)" class="text-blue-500 mt-1">{{t("addAFailedObject")}}</button>
      </div>

      <div v-show="!isEditMode && isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.A_IMPRIMER && utilisateurStore.role === enumeration.role.ADMIN" class="w-full flex flex-row justify-center">
        <button @click="setStatusWIP" class="bg-etat-encours text-white py-1 px-2 rounded-lg mt-3">{{t("changeToPrinting")}}</button>
      </div>

      <div v-show="!isEditMode && isShowingAdminButton && produit.etat.libelle === enumeration.etatObjet.A_IMPRIMER" class="w-full flex flex-row justify-center">
        <button @click="setStatusCancel" class="bg-etat-annulee text-white py-1 px-2 rounded-lg mt-3">{{t("cancelProduct")}}</button>
      </div>

    </div>

    <div class="mt-2 flex flex-row justify-center items-center" @click="() => {showFailedPrint = !showFailedPrint}"
      v-show="produit.failed && produit.failed.length > 0">
      <span class="mr-2 select-none">{{t("showFailedPrint")}}</span>
      <font-awesome-icon icon="fas fa-chevron-down" v-show="!showFailedPrint"/>
      <font-awesome-icon icon="fas fa-chevron-up" v-show="showFailedPrint"/>
    </div>

    <div v-show="showFailedPrint">
      <div v-for="failed of produit.failed" :key="failed" class="rounded-lg bg-white shadow-md mt-2 p-2">
        <p>cause: {{failed.cause}}</p>
        <p>infos: {{failed.poid}} - {{converter.minToDays(failed.temp)}}</p>
        <span class="text-gray-500">{{converter.convertIsoToYYMMDDHHmmDate(failed.date)}}</span>
      </div>
    </div>

    <div class="mt-4 text-center grid grid-cols-2 gap-2">
      <button v-show="!isEditMode" @click="downloadStl" class="bg-blue-500 text-white px-4 py-2 rounded-md w-full flex justify-center items-center">
        <span v-show="!isDownloadStl">{{t("downloadStl")}}</span>
        <loading-dot v-show="isDownloadStl" />
      </button>

      <input
          type="file"
          @change="onFileChange"
          :id="'productStlFile' + props.produit.uuid"
          name="productStlFile"
          class="hidden"
          accept=".stl"
      />

      <label v-show="isEditMode" :for="'productStlFile' + props.produit.uuid" class="cursor-pointer col-span-2 bg-purple-500 text-white p-3 rounded w-full text-center hover:bg-purple-700 active:bg-purple-900 mt-2 whitespace-nowrap text-ellipsis overflow-x-hidden">
        <span>{{ editValueStl.fileName || t("chooseAStl") }}</span>
      </label>

      <button @click="show3DRenderModal" class="bg-blue-500 w-full text-white px-4 py-2 rounded-md flex justify-center items-center">
        <span>{{t("show3D")}}</span>
      </button>

      <button @click="() => {isEditMode = true}" v-show="!isEditMode && produit.etat.libelle === enumeration.etatObjet.A_IMPRIMER" class="bg-blue-500 col-span-2 w-full text-white px-4 py-2 rounded-md flex justify-center items-center">
        <span>{{t("editAction")}}</span>
      </button>

      <button @click="updateProduit" v-show="isEditMode" class="bg-blue-500 text-white px-4 py-2 rounded-md w-full flex justify-center items-center">
        <span>{{t("validate")}}</span>
      </button>
    </div>

    <div v-show="!isEditMode" @click="showAdminButton" class="absolute right-4 top-1/5 bottom-1/2 text-xl text-blue-500 cursor-pointer">
      <font-awesome-icon v-show="(utilisateurStore.role === enumeration.role.ADMIN && produit.etat.libelle !== enumeration.etatObjet.ANNULE && !isShowingAdminButton && (produit.poid === 0 || produit.temp === 0) && etatCommande !== enumeration.etatCommande.ANNULEE) || (utilisateurStore.role === enumeration.role.CLIENT && produit.etat.libelle === enumeration.etatObjet.A_IMPRIMER)" icon="fas fa-arrow-right"></font-awesome-icon>
    </div>
    <div v-show="produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION" @click="setToPrinted" class="absolute right-4 top-1/5 bottom-1/2 text-xl text-blue-500 cursor-pointer">
      <font-awesome-icon v-show="utilisateurStore.role === enumeration.role.ADMIN && isShowingAdminButton" icon="fa fa-check"></font-awesome-icon>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue';
import EtatChips from "@/components/EtatChips";
import LoadingText from "@/components/LoadingText";
import xhr from "@/utils/xhr";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {CommandeStores} from "@/stores/CommandeStores";
import LoadingDot from "@/components/LoadingDot";
import {enumeration} from '../../Enumeration';
import {BobineStore} from "@/stores/BobineStore";
import {parser} from "mathjs";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import converter from "../utils/Converter";
import {ProduitStores} from "@/stores/ProduitStore";
import ModalChoixBobinePourProduit from "@/components/ModalChoixBobinePourProduit.vue";
import {CouleurStore} from "@/stores/CouleurStore";

const isShowingAdminButton = ref(false);
const isEditMode = ref(false);
const poidEdit = ref();
const tempsEdit = ref();
const printerUsed = ref(-1);
const isDownloadStl = ref(false);
const showFailedPrint = ref(false);
const selectedBobines = ref([]);
const showModalBobine = ref(false);
const editValueStl = ref({fileName: props.produit.nom});
const editName = ref(props.produit.nom);
const editColor = ref(props.produit.couleur.uuid);
const editQuantity = ref(props.produit.nombre);
const editFilling = ref(props.produit.remplissage);

const props = defineProps({
  produit: Object,
  etatCommande: String,
  showCreateFailedModal: Function,
  show3DRenderModalByUuid: Function,
  show3DRenderModalByBase64: Function
});

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const commandeStore = CommandeStores();
const bobineStore =  BobineStore();
const imprimanteStore = ImprimantesStore();
const produitStore = ProduitStores();
const couleurStore = CouleurStore();

const bobinesDeLaCouleurDuProduit = computed(() => {
  const filteredBobines = bobineStore.bobines.filter(bobine => bobine.couleur.uuid === props.produit.couleur.uuid);
  // Si aucune bobine correspondante n'est trouvée, retourner toutes les bobines
  return filteredBobines.length > 0 ? filteredBobines : bobineStore.bobines;
});

const show3DRenderModal = () => {
  if(editValueStl.value.fichier && isEditMode.value) {
    props.show3DRenderModalByBase64(editValueStl.value.fichier);
  } else {
    props.show3DRenderModalByUuid(props.produit.uuid);
  }
}

const onFileChange = (event) => {
  const file = event.target.files[0];

  const reader = new FileReader();
  reader.readAsDataURL(file);


  reader.onload = () => {
    editValueStl.value = {fileName: file.name, fichier: reader.result};
  };
}


const downloadStl = async () => {
  produitStore.getStl(props.produit.uuid, (response) => {
    const stlFormated = response.response

    const link = document.createElement('a');
    link.href = stlFormated
    link.download = props.produit.nom + ".stl";
    link.click();

    isDownloadStl.value = false;
  });
}

const showAdminButton = () => {
  isShowingAdminButton.value = true;
}

const setStatusWIP = async () => {
  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/wip`, undefined, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productStatusUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.etat.libelle = enumeration.etatObjet.EN_IMPRESSION;
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false;
      }
    });
}

const setStatusCancel = async () => {

  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/cancel`, undefined, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productStatusUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.etat.libelle = enumeration.etatObjet.ANNULE;
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false;
      }
    });
}

const updateProduit = () => {
  if(!editQuantity.value || editQuantity.value <= 0 || !editName.value || editName.value.trim() === "") {
    notificationStore.addNotification(
        t("needFillAllFields"),
        "error");
    return;
  }

  const data = {
    nom: editName.value,
    uuidCouleur: editColor.value,
    nombre: editQuantity.value,
    remplissage: editFilling.value,
    stl: editValueStl.value.fichier
  }

  xhr.addRequestToQueue("PUT", "/produits/" + props.produit.uuid, data, true, true, false,
    (response) => {
      if(response.code !== 200) {
        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productEditedSuccessfully"),
            "success");

        isEditMode.value = false;

        const selectedCommande = commandeStore.commandeSelected;

        const produit = selectedCommande.produits.find(p => p.uuid === props.produit.uuid);

        produit.nom = editName.value;
        produit.couleur = couleurStore.couleurs.find(c => c.uuid === editColor.value);
        produit.nombre = editQuantity.value;
        produit.remplissage = editFilling.value;

        editValueStl.value.fileName = editName.value;
        delete editValueStl.value.fichier;

        if(editValueStl.value.fichier) {
          produit.fichier = editValueStl.value.fichier;
        }
      }
  })
}

const setToPrinted = async () => {
  if(!poidEdit.value || poidEdit.value <= 0 || !tempsEdit.value || poidEdit.value <= 0 && selectedBobines.value.length > 0 && printerUsed.value === -1) {
    notificationStore.addNotification(
        t("needFillAllFields"),
        "error");
    return;
  }

  let isGoodForTemp;
  try {
    parser().evaluate(tempsEdit.value);
    isGoodForTemp = true;
  } catch(e) {
    isGoodForTemp = false;
  }

  if(isGoodForTemp) {
    notificationStore.addNotification(
        t("weightNeedBeNumber"),
        "error");
    return;
  }

  const formatedUsedBobine = selectedBobines.value.map(bobine => {return {uuid: bobine.bobine.uuid, nbObjet: bobine.nbObjet}})

  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/printed`,
    {poid: poidEdit.value, temp: tempsEdit.value, bobines: formatedUsedBobine, uuidImprimante: printerUsed.value}, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("weightAndTimeUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.poid = poidEdit.value;
        newProduit.temp = tempsEdit.value;
        newProduit.etat.libelle = enumeration.etatObjet.IMPRIMEE;
        newProduit.imprimante = imprimanteStore.imprimantes.find(imprimante => imprimante.uuid === printerUsed.value);
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false


        selectedBobines.value.forEach(bobine => {
          const bobineSelected = bobineStore.bobines.find(b => b.uuid === bobine.bobine.uuid);
          bobineSelected.poidsRestant -= (poidEdit.value * bobine.nbObjet);

          if(bobineSelected.poidsRestant <= 0) {
            bobineStore.bobines = bobineStore.bobines.filter(b => b.uuid !== bobine.bobine.uuid);
          } else {
            bobineStore.bobines = bobineStore.bobines.map(b => {
              if(b.uuid === bobine.bobine.uuid) {
                return bobineSelected;
              }
              return b;
            });
          }
        });
      }
    });
}

const resolveMathTemps = () => {
  try {
    tempsEdit.value = parser().evaluate(tempsEdit.value);
  } catch(e) {
    console.log("not good syntax");
  }
}

const closeModalSelectedBobine = (bobineSelected) => {
  if(bobineSelected) {
    selectedBobines.value = bobineSelected;
  }

  showModalBobine.value = false;
}

</script>

<style scoped>
</style>