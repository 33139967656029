const Traduction = {
    fr: {
        profit : "Bénéfice",
        productsByPrinter: "Produits par imprimante",
        productEditedSuccessfully: "Produit modifié avec succès",
        selectCoil: "Selectionner des bobines",
        cancel2: "Annuler",
        numberObjetAvailable: "Nombre d'objet restant",
        allClient: "Tout les clients",
        allStatus: "Tout les status",
        noOrderCorresponding: "Aucune commande ne correspond à votre recherche",
        selectAState: "Selectionne un état",
        errorDuringGetStates: "Erreur lors de la récupération des états",
        selectAFilament: "Selectionne un filament",
        filamentWithDots: "Filaments : ",
        typeFilamentCreated: "Type de filament crée",
        errorDuringCreateTypeFilament: "Erreur lors de la creation du type de filament",
        typeFilamentDeleted: "Type de filament supprimé",
        errorDuringDeleteFilamentType: "Erreur lors de la suppression du type de filament",
        AlertDeleteTypeFilament: "Attention ! Vous êtes sur le point de supprimer le type de filament. Vous êtes sûr de vouloir la supprimer ?",
        typeFilamentEdited: "Le type de filament à été modifié",
        errorDuringEditingTypeFilament: "Erreur lors de l'édition du type de filament",
        libelleCannotBeEmpty: "Le libelle ne peut pas être vide",
        noTypeFilament: "Aucun type de filament",
        errorDuringLoadFilamentType: "Erreur lors du chargement des types de filaments",
        label: "Libellé",
        newLabelTypeOfFilament: "Nouveau libelle du type de filament",
        filamentsType: "Les types de filaments",
        youNeedToSelectABrand: "Vous devez sélectionner une marque",
        selectABrand: 'Sélectionnez une marque',
        brandWithDots: "Marque : ",
        AlertDeleteBrand: "Attention ! Vous êtes sur le point de supprimer la marque. Vous êtes sûr de vouloir la supprimer ?",
        brandDeleted: "Marque supprimé avec succès",
        errorDuringDeleteMarque: "Erreur durant la suppression de la marque",
        noBrand: "Aucune marque",
        brandEdited: "Marques modifiés avec succès",
        errorDuringEditBrand: "Erreur lors de la modification de la marque",
        brandCreated: "Marque crée avec succès",
        errorDuringCreateBrand: "Erreur durant la création de la marque",
        needFillBrandName: "Veuillez saisir le nom de la marque",
        brandName: "Nom de la marque",
        errorDuringLoadBrand: "Erreur lors du chargement des marques",
        brands: "Les marques",
        closeAction: "Fermer",
        show3D: "Afficher en 3D",
        showFailedPrint: "Afficher les impressions raté",
        causeWithDots: "Cause : ",
        failedCreated: "\"Echec\" crée avec succès",
        errorDuringCreatFailed: "Erreur durant la création de \"l'échec\"",
        needFillCause: "Veuillez saisir la cause",
        causeOfFailed: "Cause de l'échec",
        TimeInfZero: "Le temps doit être supérieur à 0.",
        weightInfZero: "Le poids doit être supérieur à 0.",
        needFillCoil: "Veuillez saisir une bobine.",
        needFillPrinter: "Veuillez saisir une imprimante.",
        addAFailedObject: "Ajouter un objet raté",
        consumption: "Consommation :",
        totalPriceKwhConsumption: "Coût total de la consommation (en €) :",
        consumptionKwh: "Consommation (en KWh) :",
        avgTimeParPiece: "Temps moyen par piece :",
        avgProductPriceParPiece: "Coût de production moyen par piece :",
        statsPrinter: "Statistiques de l'imprimante",
        sort: "tri",
        chooseSort: "Choisir un tri",
        sortByNumber: "Trier par numéro",
        sortByWeight: "Trier par poids",
        asc: "Croissant",
        desc: "Décroissant",
        successSetCoilUndefective: "Bobine marquée comme non défectueuse",
        errorDuringActiveInformations: "Erreur lors de la récupération des informations actives",
        errorDuringInformations: "Erreur lors de la récupération des informations",
        informations: "Les informations",
        endDate: "Date de fin",
        any: "aucune",
        attentionAlertDeleteInformation: "Attention ! Vous êtes sur le point de supprimer l'information. Vous êtes sûr de vouloir la supprimer ?",
        errorDuringDeleteInformation: "Erreur lors de la suppression de l'information",
        informationDeleted: "Information supprimée avec succès",
        messageCannotBeEmpty: "Le message ne peut pas être vide",
        errorDuringEditInformation: "Erreur lors de la modification de l'information",
        informationEdited: "Information modifiée avec succès",
        noInformation: "Aucune information",
        informationMessage: "Message de l'information",
        errorDuringCreateInformation: "Erreur lors de la création de l'information",
        informationCreate: "Information créée avec succès",
        chooseAPrinter: "Choisir une imprimante",
        printerEdited: "Imprimante modifiée avec succès",
        errorDuringEditPrinter: "Erreur lors de la modification de l'imprimante",
        printerDeleted: "Imprimante supprimée avec succès",
        errorDuringDeletePrinter: "Erreur lors de la suppression de l'imprimante",
        attentionAlertDeletePrinter: "Attention ! Vous êtes sur le point de supprimer l'imprimante ",
        attentionAlertDeletePrinterEnd: ". Cette action est irréversible. Êtes-vous sûr de vouloir la supprimer ?",
        urlImgPrinter: "url image de l'imprimante",
        printerCreated: "Imprimante créée avec succès",
        errorDuringCreatePrinter: "Erreur lors de la création de l'imprimante",
        consommation: 'Consommation (en Watt)',
        model: 'Modèle',
        brand: "Marque",
        printers: "Les imprimantes",
        printerWithDot: "Imprimante : ",
        errorDuringGetPrinters: "Erreur lors de la récupération des imprimantes",
        filling: 'Remplissage',
        fillingWithDots: 'Remplissage : ',
        productName: 'Nom du produit',
        color: 'Couleur',
        colorWithDots: 'Couleur : ',
        selectAColor: 'Sélectionnez une couleur',
        addColor: 'Ajouter une couleur',
        quantity: 'Quantité',
        quantityWithDots: 'Quantité : ',
        chooseAStl: "Choisir un fichier STL",
        deleteAProduct: "Supprimer le produit",
        coil: "Bobine",
        coilWithDots: "Bobine : ",
        remainingWeight: "Poids Restant:",
        inputProductName: "Saisir le nom du produit",
        inputQuantity: "Saisir la quantité",
        presumedDefective: "Présumé défectueuse",
        errorSetCoilDefective: "Erreur lors du marquage de la bobine comme défectueuse",
        successSetCoilDefective: "Bobine marquée comme défectueuse",
        newCoilWeight: "Nouveau poids de la bobine",
        orderBy: "Commandé par : ",
        todo: "À faire",
        wip: "En cours",
        ended: "Terminée",
        endedAndPaid: "Terminée et payée",
        cancel: "Annulée",
        todoPrint: "À imprimer",
        printing: "En impression",
        printed: "Imprimé",
        printCancel: "Annulé",
        delivryAddressWithDots: "Adresse de livraison : ",
        orderDate: "Date de la commande : ",
        errorDuringDeleteMessage: "Erreur lors de la suppression du message",
        successDeleteMessage: "Le message a bien été supprimé",
        labelCannotBeEmpty: "Le libellé ne peut pas être vide",
        weightWithDots: "Poids : ",
        timeWithDots: "Temps : ",
        chooseACoil: "Choisir une bobine",
        downloadStl: "Télécharger STL",
        errorDuringGetOrder: "Erreur lors de la récupèration de la commande",
        errorDuringServerConnexion: "Erreur lors de la connexion au serveur",
        productStatusUpdated: "Le status du produit à bien été modifié",
        needFillAllFields: "Veuillez remplir tous les champs",
        weightNeedBeNumber: "Le poids doit être un nombre",
        weightAndTimeUpdated: "Le poid et le temps de la commande a bien été modifié",
        cancelProduct: "Annulé le produit",
        changeToPrinting: "Passer au status \"En Impression\"",
        version: "Version",
        versionDescription: "Description de la version",
        errorDuringDeleteVersion: "Erreur lors de la suppression de la version",
        versionDeleted: "Version supprimée avec succès",
        errorDuringEditVersion: "Erreur lors de la modification de la version",
        versionEdited: "Version modifiée avec succès",
        createAColor: "Créer une couleur",
        colorName: "Nom de la couleur",
        validate: "Valider",
        cancelAction: "Annuler",
        colorNameCannotBeEmpty: "Le nom de la couleur ne peut pas être vide",
        errorDuringCreateColor: "Erreur lors de la création de la couleur",
        createSucces: "créée avec succès",
        erreurDuringFillFields: "Erreur sur le remplissage des champs",
        continue: "continuer",
        confirmDelete: "Confirmer la suppression ?",
        attentionAlertDeleteCoil: "Attention ! Il reste actuellement ",
        attentionAlertDeleteCoilEnd: "gramme(s) sur cette bobine selon notre stock. Cette action est irréversible. Êtes-vous sûr de vouloir la supprimer ?",
        deleteAction: "Supprimer",
        attentionAlertDeleteColor: "Attention ! Vous êtes sur le point de supprimer la couleur ",
        attentionAlertDeleteColorEnd: ". Cette action est irréversible. Êtes-vous sûr de vouloir la supprimer ?",
        colors: "Les couleurs",
        coils: "Les bobines",
        createAnOrder: "Crée une commande",
        myOrders: "Mes commandes",
        orders: "Les commandes",
        ordersOnly: "Commandes",
        register: "Inscriptions",
        messages: "Les messages",
        contact: "Contact",
        logout: "Se déconnecter",
        login: "Se connecter",
        versionsOnly: "versions",
        myAccount: "Mon compte",
        youAreDisconnected: "Vous êtes déconnecté",
        noNeedFillFormFull: "Vous n'êtes pas obligé de remplir tout le formulaire.",
        ifChangeMailOnlyMailWillChange: "Si vous voulez changer votre email, saisissez uniquement le champs d'email.",
        modifyMyInformation: "Modifier mes informations",
        newMail: "Nouvel Email",
        newPassword: "Nouveau Mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        newDelivreryAddress: "Nouvelle Adresse de livraison",
        editAction: "Modifier",
        youNeedToBeConnectedToAccessThisPage: "Vous devez être connecté pour accéder à cette page",
        youNeedToFillOneField: "Vous devez remplir au moins un champ",
        passwordNotMatch: "Les mots de passe ne correspondent pas",
        personalInfoEditNeedToReconnect: "Information personnelles modifiées avec succès\nMerci de vous reconnecter",
        weightInGrammes: "poids en grammes",
        addAction: "Ajouter",
        statsPerColor: "Statistiques par Couleur",
        bobineList: "Liste des Bobines",
        filter: "filtre: ",
        all: "Tous",
        youNeedToBeAdminToAccessThisPage: "Vous devez être administrateur pour accéder à cette page",
        remeningWeightInfZero: "Le poids restant doit être supérieur à 0",
        youNeedToSelectAColor: "Vous devez sélectionner une couleur",
        showMore: "Afficher plus",
        noMessage: "Aucun message",
        inscriptionForm: "Formulaire d'Inscription",
        email: "Adresse e-mail",
        yourEmail: "Votre adresse e-mail",
        password: "Mot de passe",
        yourPassword: "Votre mot de passe",
        role: "Rôle",
        chooseARole: "Choisir un rôle",
        chooseALanguage: "Choisir une langue",
        delivryAdresse: "Adresse de livraison",
        delivry: "Livraison",
        registerMe: "S'inscrire",
        errorDuringRegisterUser: "Erreur lors de l'inscription de l'utilisateur",
        userRegisterSuccess: "Utilisateur inscrit avec succès",
        message: "Message",
        yourMessage: "Votre message",
        youDontHaveOrder: "Vous n'avez aucune commande",
        leftToPay: "Reste à payer",
        connection: "Connexion",
        firstname: "Prénom",
        youtFirstname: "Votre prénom",
        lastname: "Nom",
        yourLastname: "Votre nom",
        send: "Envoyer",
        errorDuringSendMessage: "Erreur lors de l'envoi du message",
        messageSent: "Message envoyé avec succès",
        createOrder: "Création d'une commande",
        createOrderFor: "Création d'une commande pour",
        selectAClient: "Sélectionnez un client",
        product: "produit",
        orderName: "Nom de la commande",
        inputOrderName: "Saisir le nom de la commande",
        confirmOrder: "Confirmer la commande",
        requireMinOneProduct: "Vous devez obligatoirement avoir au moins un produit",
        needFillCommandeName: "Vous devez saisir un nom de commande",
        youNeedSelectAClient: "Vous devez sélectionner un client",
        youNeedToAddMinOneProduct: "Vous devez ajouter au moins un produit",
        youNeedFillAllFieldsOfProduct: "Vous devez remplir tous les champs des produits",
        errorDuringCreateOrder: "Erreur lors de la création de la commande",
        errorDuringCreateOrderServerError: "Erreur lors de la création de la commande (erreur serveur)",
        orderCreated: "Commande créée avec succès",
        changeToStatus: "Passer au status",
        idWithDots: "ID : ",
        startDateWithDots: "Date de début : ",
        userWithDots: "Utilisateur : ",
        delivryWithDots: "Livraison : ",
        priceKiloWattPerHourWithDots: "Coût KW/H : ",
        priceCoilKiloWithDots: "Coût d'une bobine au kilo : ",
        priceProduction: "Coût de production : ",
        totalPrice: "Coût Total : ",
        downloadInvoice: "Télécharger la facture",
        errorDuringDownloadInvoice: "Erreur lors du téléchargement de la facture",
        orderStatusUpdated: "Le statut de la commande a bien été modifié",
        error404: "Erreur 404",
        pageNotFound: "La page que vous recherchez est introuvable.",
        backToHome: "Retour à la page d'accueil",
        numberOfOrder: "Nombre de commande :",
        orderCancel: "Commande annulé :",
        products: "Pièces",
        numberOfProducts: "Nombre de pièces :",
        avgProducts: "Nombre moyen par commande :",
        weights: "Poids",
        totalWeight: "Poids total :",
        avgWeight: "Poids moyen :",
        time: "Temps",
        totalTime: "Temps total :",
        avgTime: "Temps moyen :",
        price: "Couts",
        coilUsage: "Utilisation des bobines :",
        orderFor3Years: "Commande par mois (3 ans):",
        productFor3Years: "Produit par mois (3 ans):",
        january: "Jan",
        february: "Fév",
        march: "Mar",
        april: "Avr",
        may: "Mai",
        june: "Juin",
        july: "Juil",
        august: "Août",
        september: "Sept",
        october: "Oct",
        november: "Nov",
        december: "Déc",
        versionManagement: "Gestion des versions",
        major: "Majeur",
        minor: "Mineur",
        fix: "fix",
        addVersion: "Ajouter une version",
        needFillDescriptionForThisVersion: "Veuillez renseigner une description pour la version",
        errorDuringCreateVersion: "Erreur lors de la création de la version",
        versionCreated: "Version créée avec succès",
        errorDuringGetCoil: "Erreur lors de la récupération des bobines",
        errorDuringDeleteCoil: "Erreur lors de la suppression de la bobine",
        coilDeleted: "Bobine supprimée avec succès.",
        errorDuringCreateCoil: "Erreur lors de la création de la bobine",
        coilCreated: "Bobine créée avec succès",
        errorDuringEditCoil: "Erreur lors de la modification de la bobine",
        coilEdited: "Bobine modifier avec succes.",
        errorDuringGetOrders: "Erreur lors de la récupération des commandes.",
        pleaseRefreshPage: "Veuillez rafraîchir la page (f5) pour mettre à jour le produit",
        errorDuringGetMessage: "Erreur lors de la récupération des messages",
        errorDuringGetColors: "Erreur lors de la récupération des couleurs",
        errorDuringDeleteColors: "Erreur lors de la suppression de la couleur",
        colorDeleted: "Couleur supprimée avec succès",
        errorDuringEditColor: "Erreur lors de la modification de la couleur",
        colorEdited: "La couleur a bien été modifiée",
        errorDuringGetRoles: "Erreur lors de la récupération des rôles",
        errorDuringGetStats: "Erreur lors de la récupération des statistiques",
        stats: "Statistiques",
        versions: "Les versions",
        orderDetail: "Detail commande",
        pageNotFoundShort: "Page non trouvée",
        admin: "Administrateur",
        client: "Client",
        language: "Langue",
        french: "Français",
        english: "Anglais",
        hindi: "Hindi",
        chinese: "Chinois",
        spanish: "Espagnol",
        arabic: "Arabe",
        bengali: "Bengali",
        portuguese: "Portugais",
        russian: "Russe",
        urdu: "Urdu",
        newLanguage: "Nouvelle langue",
    },
    en: {
        profit: "Profit",
        productsByPrinter: "Products by printer",
        productEditedSuccessfully: "Product edited successfully",
        selectCoil: "Select coils",
        cancel2: "Cancel",
        numberObjetAvailable: "Number of objects remaining",
        allClient: "All clients",
        allStatus: "All statuses",
        noOrderCorresponding: "No order matches your search",
        selectAState: "Select a state",
        errorDuringGetStates: "Error during state retrieval",
        selectAFilament: "Select a filament",
        filamentWithDots: "Filaments: ",
        typeFilamentCreated: "Filament type created",
        errorDuringCreateTypeFilament: "Error during the creation of filament type",
        typeFilamentDeleted: "Filament type deleted",
        errorDuringDeleteFilamentType: "Error during the deletion of filament type",
        AlertDeleteTypeFilament: "Warning! You are about to delete the filament type. Are you sure you want to delete it?",
        typeFilamentEdited: "Filament type has been edited",
        errorDuringEditingTypeFilament: "Error during the editing of filament type",
        libelleCannotBeEmpty: "The label cannot be empty",
        noTypeFilament: "No filament type",
        errorDuringLoadFilamentType: "Error during the loading of filament types",
        label: "Label",
        newLabelTypeOfFilament: "New label of filament type",
        filamentsType: "Filament types",
        youNeedToSelectABrand: "You need to select a brand",
        selectABrand: "Select a brand",
        brandWithDots: "Brand: ",
        AlertDeleteBrand: "Warning! You are about to delete the brand. Are you sure you want to delete it?",
        brandDeleted: "Brand deleted successfully",
        errorDuringDeleteMarque: "Error during the deletion of the brand",
        noBrand: "No brand",
        brandEdited: "Brand edited successfully",
        errorDuringEditBrand: "Error during brand modification",
        brandCreated: "Brand created successfully",
        errorDuringCreateBrand: "Error during brand creation",
        needFillBrandName: "Please enter the brand name",
        brandName: "Brand name",
        errorDuringLoadBrand: "Error loading brands",
        brands: "Brands",
        closeAction: "Close",
        show3D: "Show in 3D",
        showFailedPrint: "Show Failed Prints",
        causeWithDots: "Cause: ",
        failedCreated: "\"Failed\" created successfully",
        errorDuringCreatFailed: "Error during \"Failed\" creation",
        needFillCause: "Please fill in the cause",
        causeOfFailed: "Cause of the failure",
        TimeInfZero: "Time must be greater than 0.",
        weightInfZero: "Weight must be greater than 0.",
        needFillCoil: "Please enter a coil.",
        needFillPrinter: "Please enter a printer.",
        addAFailedObject: "Add a failed object",
        consumption: "Consumption:",
        totalPriceKwhConsumption: "Total cost of consumption (in €):",
        consumptionKwh: "Consumption (in KWh):",
        avgTimeParPiece: "Average time per piece:",
        avgProductPriceParPiece: "Average production cost per piece:",
        statsPrinter: "Printer statistics",
        sort: "sort",
        chooseSort: "Choose a sort",
        sortByNumber: "Sort by number",
        sortByWeight: "Sort by weight",
        asc: "Ascending",
        desc: "Descending",
        successSetCoilUndefective: "Coil marked as non-defective",
        errorDuringActiveInformations: "Error retrieving active information",
        errorDuringInformations: "Error retrieving information",
        informations: "Information",
        endDate: "End date",
        any: "none",
        attentionAlertDeleteInformation: "Warning! You are about to delete the information. Are you sure you want to delete it?",
        errorDuringDeleteInformation: "Error deleting information",
        informationDeleted: "Information successfully deleted",
        messageCannotBeEmpty: "The message cannot be empty",
        errorDuringEditInformation: "Error editing information",
        informationEdited: "Information successfully edited",
        noInformation: "No information",
        informationMessage: "Information message",
        errorDuringCreateInformation: "Error creating information",
        informationCreate: "Information successfully created",
        chooseAPrinter: "Choose a printer",
        printerEdited: "Printer successfully edited",
        errorDuringEditPrinter: "Error during printer edit",
        printerDeleted: "Printer successfully deleted",
        errorDuringDeletePrinter: "Error during printer deletion",
        attentionAlertDeletePrinter: "Attention! You are about to delete the printer ",
        attentionAlertDeletePrinterEnd: ". This action is irreversible. Are you sure you want to delete it?",
        urlImgPrinter: "Printer image URL",
        printerCreated: "Printer successfully created",
        errorDuringCreatePrinter: "Error during printer creation",
        consommation: "Consumption (in Watts)",
        model: "Model",
        brand: "Brand",
        printers: "Printers",
        printerWithDot: "Printer: ",
        errorDuringGetPrinters: "Error during printer retrieval",
        filling: 'Filling',
        fillingWithDots: 'Filling: ',
        productName: 'Product Name',
        color: 'Color',
        colorWithDots: 'Color: ',
        selectAColor: 'Select a color',
        addColor: 'Add a color',
        quantity: 'Quantity',
        quantityWithDots: 'Quantity: ',
        chooseAStl: 'Choose an STL file',
        deleteAProduct: 'Delete the product',
        coil: 'Coil',
        coilWithDots: 'Coil: ',
        remainingWeight: 'Remaining Weight:',
        inputProductName: 'Enter the product name',
        inputQuantity: 'Enter the quantity',
        presumedDefective: 'Presumed defective',
        errorSetCoilDefective: 'Error marking the coil as defective',
        successSetCoilDefective: 'Coil marked as defective',
        newCoilWeight: 'New coil weight',
        orderBy: 'Ordered by: ',
        todo: 'To do',
        wip: 'In progress',
        ended: 'Completed',
        endedAndPaid: 'Completed and paid',
        cancel: 'Canceled',
        todoPrint: 'To print',
        printing: 'Printing',
        printed: 'Printed',
        printCancel: 'Canceled',
        delivryAddressWithDots: 'Delivery address: ',
        orderDate: 'Order date: ',
        errorDuringDeleteMessage: 'Error deleting the message',
        successDeleteMessage: 'Message successfully deleted',
        labelCannotBeEmpty: 'The label cannot be empty',
        weightWithDots: 'Weight: ',
        timeWithDots: 'Time: ',
        chooseACoil: 'Choose a coil',
        downloadStl: 'Download STL',
        errorDuringGetOrder: 'Error retrieving the order',
        errorDuringServerConnexion: 'Error connecting to the server',
        productStatusUpdated: 'Product status updated successfully',
        needFillAllFields: 'Please fill all fields',
        weightNeedBeNumber: 'Weight must be a number',
        weightAndTimeUpdated: 'Order weight and time successfully updated',
        cancelProduct: 'Cancel the product',
        changeToPrinting: 'Change status to "Printing"',
        version: 'Version',
        versionDescription: 'Version description',
        errorDuringDeleteVersion: 'Error deleting the version',
        versionDeleted: 'Version successfully deleted',
        errorDuringEditVersion: 'Error editing the version',
        versionEdited: 'Version successfully edited',
        createAColor: 'Create a color',
        colorName: 'Color name',
        validate: 'Validate',
        cancelAction: 'Cancel',
        colorNameCannotBeEmpty: 'Color name cannot be empty',
        errorDuringCreateColor: 'Error creating the color',
        createSucces: 'Successfully created',
        erreurDuringFillFields: 'Error filling in the fields',
        continue: 'Continue',
        confirmDelete: 'Confirm deletion?',
        attentionAlertDeleteCoil: 'Attention! There are currently ',
        attentionAlertDeleteCoilEnd: 'gram(s) on this coil according to our stock. This action is irreversible. Are you sure you want to delete it?',
        deleteAction: 'Delete',
        attentionAlertDeleteColor: 'Attention! You are about to delete the color ',
        attentionAlertDeleteColorEnd: '. This action is irreversible. Are you sure you want to delete it?',
        colors: 'Colors',
        coils: 'Coils',
        createAnOrder: 'Create an order',
        myOrders: 'My orders',
        orders: 'Orders',
        ordersOnly: 'Orders',
        register: 'Register',
        messages: 'Messages',
        contact: 'Contact',
        logout: 'Log out',
        login: 'Log in',
        versionsOnly: 'Versions',
        myAccount: 'My account',
        youAreDisconnected: 'You are disconnected',
        noNeedFillFormFull: 'You don’t need to fill in the entire form.',
        ifChangeMailOnlyMailWillChange: 'If you want to change your email, enter only the email field.',
        modifyMyInformation: 'Modify my information',
        newMail: 'New Email',
        newPassword: 'New Password',
        confirmPassword: 'Confirm password',
        newDelivreryAddress: 'New delivery address',
        editAction: 'Edit',
        youNeedToBeConnectedToAccessThisPage: 'You need to be logged in to access this page',
        youNeedToFillOneField: 'You need to fill in at least one field',
        passwordNotMatch: 'Passwords do not match',
        personalInfoEditNeedToReconnect: 'Personal information successfully edited\nPlease reconnect',
        weightInGrammes: 'Weight in grams',
        addAction: 'Add',
        statsPerColor: 'Statistics by Color',
        bobineList: 'Coil List',
        filter: 'Filter: ',
        all: 'All',
        youNeedToBeAdminToAccessThisPage: 'You need to be an administrator to access this page',
        remeningWeightInfZero: 'Remaining weight must be greater than 0',
        youNeedToSelectAColor: 'You need to select a color',
        showMore: 'Show more',
        noMessage: 'No message',
        inscriptionForm: 'Registration form',
        email: 'Email address',
        yourEmail: 'Your email address',
        password: 'Password',
        yourPassword: 'Your password',
        role: 'Role',
        chooseARole: 'Choose a role',
        chooseALanguage: 'Choose a language',
        delivryAdresse: 'Delivery address',
        delivry: 'Delivery',
        registerMe: 'Register',
        errorDuringRegisterUser: 'Error registering user',
        userRegisterSuccess: 'User successfully registered',
        message: 'Message',
        yourMessage: 'Your message',
        youDontHaveOrder: 'You have no orders',
        leftToPay: 'Left to pay',
        connection: 'Connection',
        firstname: 'First name',
        youtFirstname: 'Your first name',
        lastname: 'Last name',
        yourLastname: 'Your last name',
        send: 'Send',
        errorDuringSendMessage: 'Error sending the message',
        messageSent: 'Message sent successfully',
        createOrder: 'Create an order',
        createOrderFor: 'Create an order for',
        selectAClient: 'Select a client',
        product: 'Product',
        orderName: 'Order name',
        inputOrderName: 'Enter the order name',
        confirmOrder: 'Confirm the order',
        requireMinOneProduct: 'You must have at least one product',
        needFillCommandeName: 'You must enter an order name',
        youNeedSelectAClient: 'You need to select a client',
        youNeedToAddMinOneProduct: 'You need to add at least one product',
        youNeedFillAllFieldsOfProduct: 'You need to fill in all fields for the products',
        errorDuringCreateOrder: 'Error creating the order',
        errorDuringCreateOrderServerError: 'Error creating the order (server error)',
        orderCreated: 'Order successfully created',
        changeToStatus: 'Change to status',
        idWithDots: 'ID: ',
        startDateWithDots: 'Start date: ',
        userWithDots: 'User: ',
        delivryWithDots: 'Delivery: ',
        priceKiloWattPerHourWithDots: 'Cost KW/H: ',
        priceCoilKiloWithDots: 'Cost of a coil per kilo: ',
        priceProduction: 'Production cost: ',
        totalPrice: 'Total cost: ',
        downloadInvoice: 'Download invoice',
        errorDuringDownloadInvoice: 'Error downloading the invoice',
        orderStatusUpdated: 'Order status successfully updated',
        error404: 'Error 404',
        pageNotFound: 'The page you are looking for is not found.',
        backToHome: 'Back to home page',
        numberOfOrder: 'Number of orders:',
        orderCancel: 'Order canceled:',
        products: 'Products',
        numberOfProducts: 'Number of products:',
        avgProducts: 'Average number per order:',
        weights: 'Weights',
        totalWeight: 'Total weight:',
        avgWeight: 'Average weight:',
        time: 'Time',
        totalTime: 'Total time:',
        avgTime: 'Average time:',
        price: 'Costs',
        coilUsage: 'Coil usage:',
        orderFor3Years: 'Orders per month (3 years):',
        productFor3Years: 'Products per month (3 years):',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
        versionManagement: 'Version management',
        major: 'Major',
        minor: 'Minor',
        fix: 'Fix',
        addVersion: 'Add a version',
        needFillDescriptionForThisVersion: 'Please provide a description for the version',
        errorDuringCreateVersion: 'Error creating the version',
        versionCreated: 'Version successfully created',
        errorDuringGetCoil: 'Error retrieving coils',
        errorDuringDeleteCoil: 'Error deleting the coil',
        coilDeleted: 'Coil successfully deleted.',
        errorDuringCreateCoil: 'Error creating the coil',
        coilCreated: 'Coil successfully created',
        errorDuringEditCoil: 'Error editing the coil',
        coilEdited: 'Coil successfully edited.',
        errorDuringGetOrders: 'Error retrieving orders.',
        pleaseRefreshPage: 'Please refresh the page (f5) to update the product',
        errorDuringGetMessage: 'Error retrieving messages',
        errorDuringGetColors: 'Error retrieving colors',
        errorDuringDeleteColors: 'Error deleting the color',
        colorDeleted: 'Color successfully deleted',
        errorDuringEditColor: 'Error editing the color',
        colorEdited: 'Color successfully edited',
        errorDuringGetRoles: 'Error retrieving roles',
        errorDuringGetStats: 'Error retrieving statistics',
        stats: 'Statistics',
        versions: 'Versions',
        orderDetail: 'Order detail',
        pageNotFoundShort: 'Page not found',
        admin: 'Administrator',
        client: 'Client',
        language: 'Language',
        french: 'French',
        english: 'English',
        hindi: 'Hindi',
        chinese: 'Chinese',
        spanish: 'Spanish',
        arabic: 'Arabic',
        bengali: 'Bengali',
        portuguese: 'Portuguese',
        russian: 'Russian',
        urdu: 'Urdu',
        newLanguage: 'New language',
    },
    hi: {
        profit: "लाभ",
        productsByPrinter: "प्रिंटर द्वारा उत्पाद",
        productEditedSuccessfully: "उत्पाद सफलतापूर्वक संपादित किया गया",
        selectCoil: "कॉइल्स का चयन करें",
        cancel2: "रद्द करें",
        numberObjetAvailable: "शेष वस्तुओं की संख्या",
        allClient: "सभी ग्राहक",
        allStatus: "सभी स्थिति",
        noOrderCorresponding: "आपकी खोज से मेल खाने वाला कोई आदेश नहीं है",
        selectAState: "एक राज्य चुनें",
        errorDuringGetStates: "राज्यों को प्राप्त करने में त्रुटि",
        selectAFilament: "एक फिलामेंट चुनें",
        filamentWithDots: "फिलामेंट्स: ",
        typeFilamentCreated: "फिलामेंट प्रकार बनाया गया",
        errorDuringCreateTypeFilament: "फिलामेंट प्रकार बनाने के दौरान त्रुटि",
        typeFilamentDeleted: "फिलामेंट प्रकार हटाया गया",
        errorDuringDeleteFilamentType: "फिलामेंट प्रकार को हटाने के दौरान त्रुटि",
        AlertDeleteTypeFilament: "चेतावनी! आप फिलामेंट प्रकार को हटाने वाले हैं। क्या आप इसे हटाने के लिए सुनिश्चित हैं?",
        typeFilamentEdited: "फिलामेंट प्रकार संपादित किया गया है",
        errorDuringEditingTypeFilament: "फिलामेंट प्रकार को संपादित करने के दौरान त्रुटि",
        libelleCannotBeEmpty: "लेबल खाली नहीं हो सकता",
        noTypeFilament: "कोई फिलामेंट प्रकार नहीं",
        errorDuringLoadFilamentType: "फिलामेंट प्रकारों को लोड करते समय त्रुटि",
        label: "लेबल",
        newLabelTypeOfFilament: "फिलामेंट प्रकार का नया लेबल",
        filamentsType: "फिलामेंट प्रकार",
        youNeedToSelectABrand: "आपको एक ब्रांड चुनना होगा",
        selectABrand: "एक ब्रांड चुनें",
        brandWithDots: "ब्रांड: ",
        AlertDeleteBrand: "चेतावनी! आप ब्रांड हटाने वाले हैं। क्या आप वाकई इसे हटाना चाहते हैं?",
        brandDeleted: "ब्रांड सफलतापूर्वक हटाया गया",
        errorDuringDeleteMarque: "ब्रांड हटाने के दौरान त्रुटि",
        noBrand: "कोई ब्रांड नहीं",
        brandEdited: "ब्रांड सफलतापूर्वक संपादित किया गया",
        errorDuringEditBrand: "ब्रांड संशोधन के दौरान त्रुटि",
        brandCreated: "ब्रांड सफलतापूर्वक बनाया गया",
        errorDuringCreateBrand: "ब्रांड निर्माण के दौरान त्रुटि",
        needFillBrandName: "कृपया ब्रांड का नाम दर्ज करें",
        brandName: "ब्रांड नाम",
        errorDuringLoadBrand: "ब्रांड लोड करने के दौरान त्रुटि",
        brands: "ब्रांड्स",
        closeAction: "बंद करें",
        show3D: "3D में दिखाएं",
        showFailedPrint: "विफल प्रिंट दिखाएं",
        causeWithDots: "कारण: ",
        failedCreated: "\"विफल\" सफलतापूर्वक बनाया गया",
        errorDuringCreatFailed: "\"विफल\" निर्माण के दौरान त्रुटि",
        needFillCause: "कृपया कारण दर्ज करें",
        causeOfFailed: "विफलता का कारण",
        TimeInfZero: "समय 0 से अधिक होना चाहिए।",
        weightInfZero: "वजन 0 से अधिक होना चाहिए।",
        needFillCoil: "कृपया एक कॉइल दर्ज करें।",
        needFillPrinter: "कृपया एक प्रिंटर दर्ज करें।",
        addAFailedObject: "एक विफल वस्तु जोड़ें",
        consumption: "खपत:",
        totalPriceKwhConsumption: "उपभोग की कुल लागत (यू में):",
        consumptionKwh: "खपत (केडब्ल्यूएच में):",
        avgTimeParPiece: "प्रति टुकड़ा औसत समय:",
        avgProductPriceParPiece: "प्रति टुकड़ा औसत उत्पादन लागत:",
        statsPrinter: "प्रिंटर सांख्यिकी",
        sort: "क्रमबद्ध करें",
        chooseSort: "क्रमबद्ध करें चुनें",
        sortByNumber: "संख्या द्वारा क्रमबद्ध करें",
        sortByWeight: "वजन द्वारा क्रमबद्ध करें",
        asc: "आरोही",
        desc: "अवरोही",
        successSetCoilUndefective: "कॉइल को गैर-त्रुटिपूर्ण के रूप में चिह्नित किया गया",
        errorDuringActiveInformations: "सक्रिय जानकारी पुनः प्राप्त करने में त्रुटि",
        errorDuringInformations: "जानकारी पुनः प्राप्त करने में त्रुटि",
        informations: "जानकारी",
        endDate: "समाप्ति तिथि",
        any: "कोई नहीं",
        attentionAlertDeleteInformation: "सावधान! आप जानकारी को हटाने वाले हैं। क्या आप इसे हटाना चाहते हैं?",
        errorDuringDeleteInformation: "जानकारी को हटाने में त्रुटि",
        informationDeleted: "जानकारी सफलतापूर्वक हटाई गई",
        messageCannotBeEmpty: "संदेश खाली नहीं हो सकता",
        errorDuringEditInformation: "जानकारी को संपादित करने में त्रुटि",
        informationEdited: "जानकारी सफलतापूर्वक संपादित की गई",
        noInformation: "कोई जानकारी नहीं",
        informationMessage: "जानकारी संदेश",
        errorDuringCreateInformation: "जानकारी बनाने में त्रुटि",
        informationCreate: "जानकारी सफलतापूर्वक बनाई गई",
        chooseAPrinter: "प्रिंटर चुनें",
        printerEdited: "प्रिंटर सफलतापूर्वक संपादित किया गया",
        errorDuringEditPrinter: "प्रिंटर संपादन के दौरान त्रुटि",
        printerDeleted: "प्रिंटर सफलतापूर्वक हटाया गया",
        errorDuringDeletePrinter: "प्रिंटर हटाने के दौरान त्रुटि",
        attentionAlertDeletePrinter: "ध्यान दें! आप प्रिंटर को हटाने वाले हैं ",
        attentionAlertDeletePrinterEnd: ". यह क्रिया अपरिवर्तनीय है। क्या आप वाकई इसे हटाना चाहते हैं?",
        urlImgPrinter: "प्रिंटर की छवि URL",
        printerCreated: "प्रिंटर सफलतापूर्वक बनाया गया",
        errorDuringCreatePrinter: "प्रिंटर बनाने के दौरान त्रुटि",
        consommation: "खपत (वॉट में)",
        model: "मॉडल",
        brand: "ब्रांड",
        printers: "प्रिंटर",
        printerWithDot: "प्रिंटर: ",
        errorDuringGetPrinters: "प्रिंटर पुनर्प्राप्ति के दौरान त्रुटि",
        filling: 'भराई',
        fillingWithDots: 'भराई: ',
        productName: 'उत्पाद का नाम',
        color: 'रंग',
        colorWithDots: 'रंग: ',
        selectAColor: 'एक रंग चुनें',
        addColor: 'एक रंग जोड़ें',
        quantity: 'मात्रा',
        quantityWithDots: 'मात्रा: ',
        chooseAStl: 'एक STL फ़ाइल चुनें',
        deleteAProduct: 'उत्पाद को हटाएं',
        coil: 'कॉइल',
        coilWithDots: 'कॉइल: ',
        remainingWeight: 'शेष वजन:',
        inputProductName: 'उत्पाद का नाम दर्ज करें',
        inputQuantity: 'मात्रा दर्ज करें',
        presumedDefective: 'संदिग्ध दोषपूर्ण',
        errorSetCoilDefective: 'कॉइल को दोषपूर्ण के रूप में चिह्नित करने में त्रुटि',
        successSetCoilDefective: 'कॉइल को दोषपूर्ण के रूप में चिह्नित किया गया',
        newCoilWeight: 'नया कॉइल वजन',
        orderBy: 'द्वारा आदेशित: ',
        todo: 'करना है',
        wip: 'प्रगति पर',
        ended: 'समाप्त',
        endedAndPaid: 'समाप्त और भुगतान किया गया',
        cancel: 'रद्द',
        todoPrint: 'प्रिंट करने के लिए',
        printing: 'प्रिंट हो रहा है',
        printed: 'प्रिंटेड',
        printCancel: 'रद्द किया गया',
        delivryAddressWithDots: 'डिलीवरी पता: ',
        orderDate: 'आदेश की तारीख: ',
        errorDuringDeleteMessage: 'संदेश को हटाते समय त्रुटि',
        successDeleteMessage: 'संदेश सफलतापूर्वक हटाया गया',
        labelCannotBeEmpty: 'लेबल खाली नहीं हो सकता',
        weightWithDots: 'वजन: ',
        timeWithDots: 'समय: ',
        chooseACoil: 'एक कॉइल चुनें',
        downloadStl: 'STL डाउनलोड करें',
        errorDuringGetOrder: 'आदेश प्राप्त करने में त्रुटि',
        errorDuringServerConnexion: 'सर्वर से कनेक्शन में त्रुटि',
        productStatusUpdated: 'उत्पाद की स्थिति सफलतापूर्वक अपडेट की गई',
        needFillAllFields: 'कृपया सभी फ़ील्ड भरें',
        weightNeedBeNumber: 'वजन एक संख्या होना चाहिए',
        weightAndTimeUpdated: 'आदेश का वजन और समय सफलतापूर्वक अपडेट किया गया',
        cancelProduct: 'उत्पाद रद्द करें',
        changeToPrinting: 'स्थिति को "प्रिंट हो रहा है" में बदलें',
        version: 'संस्करण',
        versionDescription: 'संस्करण विवरण',
        errorDuringDeleteVersion: 'संस्करण को हटाने में त्रुटि',
        versionDeleted: 'संस्करण सफलतापूर्वक हटाया गया',
        errorDuringEditVersion: 'संस्करण को संपादित करने में त्रुटि',
        versionEdited: 'संस्करण सफलतापूर्वक संपादित किया गया',
        createAColor: 'एक रंग बनाएं',
        colorName: 'रंग का नाम',
        validate: 'मान्य करें',
        cancelAction: 'रद्द करें',
        colorNameCannotBeEmpty: 'रंग का नाम खाली नहीं हो सकता',
        errorDuringCreateColor: 'रंग बनाने में त्रुटि',
        createSucces: 'सफलतापूर्वक बनाया गया',
        erreurDuringFillFields: 'फ़ील्ड भरने में त्रुटि',
        continue: 'जारी रखें',
        confirmDelete: 'हटाने की पुष्टि करें?',
        attentionAlertDeleteCoil: 'ध्यान दें! वर्तमान में हमारे स्टॉक के अनुसार इस कॉइल पर ',
        attentionAlertDeleteCoilEnd: 'ग्राम (ग्राम) शेष हैं। यह क्रिया अपरिवर्तनीय है। क्या आप इसे हटाने के लिए निश्चित हैं?',
        deleteAction: 'हटाएं',
        attentionAlertDeleteColor: 'ध्यान दें! आप रंग को हटाने वाले हैं ',
        attentionAlertDeleteColorEnd: '। यह क्रिया अपरिवर्तनीय है। क्या आप इसे हटाने के लिए निश्चित हैं?',
        colors: 'रंग',
        coils: 'कॉइल्स',
        createAnOrder: 'एक आदेश बनाएं',
        myOrders: 'मेरे आदेश',
        orders: 'आदेश',
        ordersOnly: 'आदेश',
        register: 'रजिस्टर करें',
        messages: 'संदेश',
        contact: 'संपर्क करें',
        logout: 'लॉग आउट करें',
        login: 'लॉग इन करें',
        versionsOnly: 'संस्करण',
        myAccount: 'मेरा खाता',
        youAreDisconnected: 'आप डिस्कनेक्ट हो गए हैं',
        noNeedFillFormFull: 'आपको पूरा फॉर्म भरने की आवश्यकता नहीं है।',
        ifChangeMailOnlyMailWillChange: 'यदि आप अपना ईमेल बदलना चाहते हैं, तो केवल ईमेल फ़ील्ड दर्ज करें।',
        modifyMyInformation: 'मेरी जानकारी संशोधित करें',
        newMail: 'नया ईमेल',
        newPassword: 'नया पासवर्ड',
        confirmPassword: 'पासवर्ड की पुष्टि करें',
        newDelivreryAddress: 'नया डिलीवरी पता',
        editAction: 'संपादित करें',
        youNeedToBeConnectedToAccessThisPage: 'इस पृष्ठ तक पहुँचने के लिए आपको लॉग इन होना होगा',
        youNeedToFillOneField: 'आपको कम से कम एक फ़ील्ड भरनी होगी',
        passwordNotMatch: 'पासवर्ड मेल नहीं खाता',
        personalInfoEditNeedToReconnect: 'व्यक्तिगत जानकारी सफलतापूर्वक संपादित की गई\nकृपया पुनः लॉगिन करें',
        weightInGrammes: 'वजन ग्राम में',
        addAction: 'जोड़ें',
        statsPerColor: 'रंग के अनुसार आंकड़े',
        bobineList: 'कॉइल सूची',
        filter: 'फ़िल्टर: ',
        all: 'सभी',
        youNeedToBeAdminToAccessThisPage: 'इस पृष्ठ तक पहुँचने के लिए आपको व्यवस्थापक होना होगा',
        remeningWeightInfZero: 'शेष वजन 0 से अधिक होना चाहिए',
        youNeedToSelectAColor: 'आपको एक रंग चुनना होगा',
        showMore: 'और दिखाएं',
        noMessage: 'कोई संदेश नहीं',
        inscriptionForm: 'पंजीकरण फॉर्म',
        email: 'ईमेल पता',
        yourEmail: 'आपका ईमेल पता',
        password: 'पासवर्ड',
        yourPassword: 'आपका पासवर्ड',
        role: 'भूमिका',
        chooseARole: 'एक भूमिका चुनें',
        chooseALanguage: 'एक भाषा चुनें',
        delivryAdresse: 'डिलीवरी पता',
        delivry: 'डिलीवरी',
        registerMe: 'पंजीकरण करें',
        errorDuringRegisterUser: 'उपयोगकर्ता पंजीकरण में त्रुटि',
        userRegisterSuccess: 'उपयोगकर्ता सफलतापूर्वक पंजीकृत हुआ',
        message: 'संदेश',
        yourMessage: 'आपका संदेश',
        youDontHaveOrder: 'आपके पास कोई आदेश नहीं है',
        leftToPay: 'भुगतान के लिए शेष',
        connection: 'संपर्क',
        firstname: 'पहला नाम',
        youtFirstname: 'आपका पहला नाम',
        lastname: 'अंतिम नाम',
        yourLastname: 'आपका अंतिम नाम',
        send: 'भेजें',
        errorDuringSendMessage: 'संदेश भेजने में त्रुटि',
        messageSent: 'संदेश सफलतापूर्वक भेजा गया',
        createOrder: 'आदेश बनाएं',
        createOrderFor: 'के लिए आदेश बनाएं',
        selectAClient: 'एक ग्राहक चुनें',
        product: 'उत्पाद',
        orderName: 'आदेश का नाम',
        inputOrderName: 'आदेश का नाम दर्ज करें',
        confirmOrder: 'आदेश की पुष्टि करें',
        requireMinOneProduct: 'आपको कम से कम एक उत्पाद होना चाहिए',
        needFillCommandeName: 'आपको आदेश का नाम दर्ज करना होगा',
        youNeedSelectAClient: 'आपको एक ग्राहक चुनना होगा',
        youNeedToAddMinOneProduct: 'आपको कम से कम एक उत्पाद जोड़ना होगा',
        youNeedFillAllFieldsOfProduct: 'आपको उत्पादों के सभी फ़ील्ड भरने होंगे',
        errorDuringCreateOrder: 'आदेश बनाते समय त्रुटि',
        errorDuringCreateOrderServerError: 'आदेश बनाते समय त्रुटि (सर्वर त्रुटि)',
        orderCreated: 'आदेश सफलतापूर्वक बनाया गया',
        changeToStatus: 'स्थिति में बदलें',
        idWithDots: 'आईडी: ',
        startDateWithDots: 'प्रारंभ तिथि: ',
        userWithDots: 'उपयोगकर्ता: ',
        delivryWithDots: 'डिलीवरी: ',
        priceKiloWattPerHourWithDots: 'लागत केडब्ल्यू/घंटा: ',
        priceCoilKiloWithDots: 'एक किलो कॉइल की लागत: ',
        priceProduction: 'उत्पादन लागत: ',
        totalPrice: 'कुल लागत: ',
        downloadInvoice: 'चालान डाउनलोड करें',
        errorDuringDownloadInvoice: 'चालान डाउनलोड करने में त्रुटि',
        orderStatusUpdated: 'आदेश की स्थिति सफलतापूर्वक अपडेट की गई',
        error404: 'त्रुटि 404',
        pageNotFound: 'आप जिस पृष्ठ की तलाश कर रहे हैं वह नहीं मिला।',
        backToHome: 'मुखपृष्ठ पर वापस जाएं',
        numberOfOrder: 'आदेशों की संख्या:',
        orderCancel: 'आदेश रद्द:',
        products: 'उत्पाद',
        numberOfProducts: 'उत्पादों की संख्या:',
        avgProducts: 'प्रति आदेश औसत संख्या:',
        weights: 'वजन',
        totalWeight: 'कुल वजन:',
        avgWeight: 'औसत वजन:',
        time: 'समय',
        totalTime: 'कुल समय:',
        avgTime: 'औसत समय:',
        price: 'लागत',
        coilUsage: 'कॉइल उपयोग:',
        orderFor3Years: 'आदेश प्रति माह (3 साल):',
        productFor3Years: 'उत्पाद प्रति माह (3 साल):',
        january: 'जनवरी',
        february: 'फरवरी',
        march: 'मार्च',
        april: 'अप्रैल',
        may: 'मई',
        june: 'जून',
        july: 'जुलाई',
        august: 'अगस्त',
        september: 'सितंबर',
        october: 'अक्टूबर',
        november: 'नवंबर',
        december: 'दिसंबर',
        versionManagement: 'संस्करण प्रबंधन',
        major: 'प्रमुख',
        minor: 'अल्प',
        fix: 'फिक्स',
        addVersion: 'एक संस्करण जोड़ें',
        needFillDescriptionForThisVersion: 'कृपया संस्करण के लिए विवरण प्रदान करें',
        errorDuringCreateVersion: 'संस्करण बनाने में त्रुटि',
        versionCreated: 'संस्करण सफलतापूर्वक बनाया गया',
        errorDuringGetCoil: 'कॉइल प्राप्त करने में त्रुटि',
        errorDuringDeleteCoil: 'कॉइल को हटाने में त्रुटि',
        coilDeleted: 'कॉइल सफलतापूर्वक हटाया गया।',
        errorDuringCreateCoil: 'कॉइल बनाने में त्रुटि',
        coilCreated: 'कॉइल सफलतापूर्वक बनाया गया',
        errorDuringEditCoil: 'कॉइल को संपादित करने में त्रुटि',
        coilEdited: 'कॉइल सफलतापूर्वक संपादित किया गया।',
        errorDuringGetOrders: 'आदेश प्राप्त करने में त्रुटि।',
        pleaseRefreshPage: 'उत्पाद को अपडेट करने के लिए कृपया पृष्ठ (f5) को ताज़ा करें',
        errorDuringGetMessage: 'संदेश प्राप्त करने में त्रुटि',
        errorDuringGetColors: 'रंग प्राप्त करने में त्रुटि',
        errorDuringDeleteColors: 'रंग को हटाने में त्रुटि',
        colorDeleted: 'रंग सफलतापूर्वक हटाया गया',
        errorDuringEditColor: 'रंग को संपादित करने में त्रुटि',
        colorEdited: 'रंग सफलतापूर्वक संपादित किया गया',
        errorDuringGetRoles: 'भूमिकाएँ प्राप्त करने में त्रुटि',
        errorDuringGetStats: 'आंकड़े प्राप्त करने में त्रुटि',
        stats: 'आंकड़े',
        versions: 'संस्करण',
        orderDetail: 'आदेश विवरण',
        pageNotFoundShort: 'पृष्ठ नहीं मिला',
        admin: 'प्रशासक',
        client: 'ग्राहक',
        language: 'भाषा',
        french: 'फ्रेंच',
        english: 'अंग्रेजी',
        hindi: 'हिंदी',
        chinese: 'चीनी',
        spanish: 'स्पेनिश',
        arabic: 'अरबी',
        bengali: 'बंगाली',
        portuguese: 'पुर्तगाली',
        russian: 'रूसी',
        urdu: 'उर्दू',
        newLanguage: 'नई भाषा',
    },
    zh: {
        profit: "利润",
        productsByPrinter: "按打印机分类的产品",
        productEditedSuccessfully: "产品修改成功",
        selectCoil: "选择线圈",
        cancel2: "取消",
        numberObjetAvailable: "剩余物体数量",
        allClient: "所有客户",
        allStatus: "所有状态",
        noOrderCorresponding: "没有符合您搜索的订单",
        selectAState: "选择一个州",
        errorDuringGetStates: "获取状态时出错",
        selectAFilament: "选择一个灯丝",
        filamentWithDots: "灯丝：",
        typeFilamentCreated: "创建了灯丝类型",
        errorDuringCreateTypeFilament: "创建灯丝类型时出错",
        typeFilamentDeleted: "灯丝类型已删除",
        errorDuringDeleteFilamentType: "删除灯丝类型时出错",
        AlertDeleteTypeFilament: "警告！您即将删除灯丝类型。确定要删除它吗？",
        typeFilamentEdited: "灯丝类型已编辑",
        errorDuringEditingTypeFilament: "编辑灯丝类型时出错",
        libelleCannotBeEmpty: "标签不能为空",
        noTypeFilament: "没有灯丝类型",
        errorDuringLoadFilamentType: "加载灯丝类型时出错",
        label: "标签",
        newLabelTypeOfFilament: "灯丝类型的新标签",
        filamentsType: "灯丝类型",
        youNeedToSelectABrand: "您需要选择一个品牌",
        selectABrand: "选择一个品牌",
        brandWithDots: "品牌：",
        AlertDeleteBrand: "警告！您即将删除该品牌。确定要删除它吗？",
        brandDeleted: "品牌删除成功",
        errorDuringDeleteMarque: "删除品牌时出错",
        noBrand: "没有品牌",
        brandEdited: "品牌编辑成功",
        errorDuringEditBrand: "修改品牌时出错",
        brandCreated: "品牌创建成功",
        errorDuringCreateBrand: "创建品牌时出错",
        needFillBrandName: "请输入品牌名称",
        brandName: "品牌名称",
        errorDuringLoadBrand: "加载品牌时出错",
        brands: "品牌",
        closeAction: "关闭",
        show3D: "显示 3D",
        showFailedPrint: "显示失败的打印",
        causeWithDots: "原因：",
        failedCreated: "\"失败\"成功创建",
        errorDuringCreatFailed: "创建\"失败\"时出错",
        needFillCause: "请填写原因",
        causeOfFailed: "失败的原因",
        TimeInfZero: "时间必须大于0。",
        weightInfZero: "重量必须大于0。",
        needFillCoil: "请填写线圈。",
        needFillPrinter: "请填写打印机。",
        addAFailedObject: "添加失败的对象",
        consumption: "消耗：",
        totalPriceKwhConsumption: "消费总成本（€）：",
        consumptionKwh: "消耗（千瓦时）：",
        avgTimeParPiece: "每件平均时间：",
        avgProductPriceParPiece: "每件平均生产成本：",
        statsPrinter: "打印机统计",
        sort: "排序",
        chooseSort: "选择排序",
        sortByNumber: "按编号排序",
        sortByWeight: "按重量排序",
        asc: "升序",
        desc: "降序",
        successSetCoilUndefective: "线圈标记为无缺陷",
        errorDuringActiveInformations: "获取活动信息时出错",
        errorDuringInformations: "获取信息时出错",
        informations: "信息",
        endDate: "结束日期",
        any: "无",
        attentionAlertDeleteInformation: "警告！您即将删除信息。确定要删除吗？",
        errorDuringDeleteInformation: "删除信息时出错",
        informationDeleted: "信息删除成功",
        messageCannotBeEmpty: "消息不能为空",
        errorDuringEditInformation: "编辑信息时出错",
        informationEdited: "信息编辑成功",
        noInformation: "没有信息",
        informationMessage: "信息消息",
        errorDuringCreateInformation: "创建信息时出错",
        informationCreate: "信息创建成功",
        chooseAPrinter: "选择打印机",
        printerEdited: "打印机成功编辑",
        errorDuringEditPrinter: "编辑打印机时出错",
        printerDeleted: "打印机成功删除",
        errorDuringDeletePrinter: "删除打印机时出错",
        attentionAlertDeletePrinter: "注意！您即将删除打印机 ",
        attentionAlertDeletePrinterEnd: "。此操作不可逆。您确定要删除它吗？",
        urlImgPrinter: "打印机图片 URL",
        printerCreated: "打印机成功创建",
        errorDuringCreatePrinter: "创建打印机时出错",
        consommation: "消耗（瓦特）",
        model: "型号",
        brand: "品牌",
        printers: "打印机",
        printerWithDot: "打印机：",
        errorDuringGetPrinters: "获取打印机时出错",
        filling: '填充',
        fillingWithDots: '填充: ',
        productName: '产品名称',
        color: '颜色',
        colorWithDots: '颜色: ',
        selectAColor: '选择颜色',
        addColor: '添加颜色',
        quantity: '数量',
        quantityWithDots: '数量: ',
        chooseAStl: '选择 STL 文件',
        deleteAProduct: '删除产品',
        coil: '线圈',
        coilWithDots: '线圈: ',
        remainingWeight: '剩余重量:',
        inputProductName: '输入产品名称',
        inputQuantity: '输入数量',
        presumedDefective: '疑似缺陷',
        errorSetCoilDefective: '标记线圈为缺陷时出错',
        successSetCoilDefective: '线圈已成功标记为缺陷',
        newCoilWeight: '新线圈重量',
        orderBy: '订购者: ',
        todo: '待办',
        wip: '进行中',
        ended: '已完成',
        endedAndPaid: '已完成并付款',
        cancel: '已取消',
        todoPrint: '待打印',
        printing: '打印中',
        printed: '已打印',
        printCancel: '取消打印',
        delivryAddressWithDots: '送货地址: ',
        orderDate: '订购日期: ',
        errorDuringDeleteMessage: '删除消息时出错',
        successDeleteMessage: '消息已成功删除',
        labelCannotBeEmpty: '标签不能为空',
        weightWithDots: '重量: ',
        timeWithDots: '时间: ',
        chooseACoil: '选择线圈',
        downloadStl: '下载 STL',
        errorDuringGetOrder: '获取订单时出错',
        errorDuringServerConnexion: '服务器连接出错',
        productStatusUpdated: '产品状态已成功更新',
        needFillAllFields: '请填写所有字段',
        weightNeedBeNumber: '重量必须是数字',
        weightAndTimeUpdated: '订单重量和时间已成功更新',
        cancelProduct: '取消产品',
        changeToPrinting: '更改为“打印中”状态',
        version: '版本',
        versionDescription: '版本描述',
        errorDuringDeleteVersion: '删除版本时出错',
        versionDeleted: '版本已成功删除',
        errorDuringEditVersion: '编辑版本时出错',
        versionEdited: '版本已成功编辑',
        createAColor: '创建颜色',
        colorName: '颜色名称',
        validate: '验证',
        cancelAction: '取消',
        colorNameCannotBeEmpty: '颜色名称不能为空',
        errorDuringCreateColor: '创建颜色时出错',
        createSucces: '创建成功',
        erreurDuringFillFields: '填写字段时出错',
        continue: '继续',
        confirmDelete: '确认删除？',
        attentionAlertDeleteCoil: '注意！根据我们的库存，目前此线圈上剩余 ',
        attentionAlertDeleteCoilEnd: '克。这一操作不可逆。您确定要删除吗？',
        deleteAction: '删除',
        attentionAlertDeleteColor: '注意！您即将删除颜色 ',
        attentionAlertDeleteColorEnd: '。这一操作不可逆。您确定要删除吗？',
        colors: '颜色',
        coils: '线圈',
        createAnOrder: '创建订单',
        myOrders: '我的订单',
        orders: '订单',
        ordersOnly: '订单',
        register: '注册',
        messages: '消息',
        contact: '联系',
        logout: '登出',
        login: '登录',
        versionsOnly: '版本',
        myAccount: '我的账户',
        youAreDisconnected: '您已断开连接',
        noNeedFillFormFull: '您不必填写整个表格。',
        ifChangeMailOnlyMailWillChange: '如果您想更改电子邮件，只需填写电子邮件字段。',
        modifyMyInformation: '修改我的信息',
        newMail: '新电子邮件',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        newDelivreryAddress: '新送货地址',
        editAction: '编辑',
        youNeedToBeConnectedToAccessThisPage: '您需要登录才能访问此页面',
        youNeedToFillOneField: '您需要填写至少一个字段',
        passwordNotMatch: '密码不匹配',
        personalInfoEditNeedToReconnect: '个人信息已成功修改\n请重新登录',
        weightInGrammes: '重量（克）',
        addAction: '添加',
        statsPerColor: '按颜色统计',
        bobineList: '线圈列表',
        filter: '筛选: ',
        all: '全部',
        youNeedToBeAdminToAccessThisPage: '您需要是管理员才能访问此页面',
        remeningWeightInfZero: '剩余重量必须大于 0',
        youNeedToSelectAColor: '您需要选择一种颜色',
        showMore: '显示更多',
        noMessage: '没有消息',
        inscriptionForm: '注册表',
        email: '电子邮件地址',
        yourEmail: '您的电子邮件地址',
        password: '密码',
        yourPassword: '您的密码',
        role: '角色',
        chooseARole: '选择角色',
        chooseALanguage: '选择语言',
        delivryAdresse: '送货地址',
        delivry: '送货',
        registerMe: '注册',
        errorDuringRegisterUser: '注册用户时出错',
        userRegisterSuccess: '用户注册成功',
        message: '消息',
        yourMessage: '您的消息',
        youDontHaveOrder: '您没有订单',
        leftToPay: '剩余支付',
        connection: '连接',
        firstname: '名字',
        youtFirstname: '您的名字',
        lastname: '姓',
        yourLastname: '您的姓',
        send: '发送',
        errorDuringSendMessage: '发送消息时出错',
        messageSent: '消息已成功发送',
        createOrder: '创建订单',
        createOrderFor: '为...创建订单',
        selectAClient: '选择客户',
        product: '产品',
        orderName: '订单名称',
        inputOrderName: '输入订单名称',
        confirmOrder: '确认订单',
        requireMinOneProduct: '您必须至少有一个产品',
        needFillCommandeName: '您必须输入订单名称',
        youNeedSelectAClient: '您需要选择一个客户',
        youNeedToAddMinOneProduct: '您需要添加至少一个产品',
        youNeedFillAllFieldsOfProduct: '您需要填写所有产品字段',
        errorDuringCreateOrder: '创建订单时出错',
        errorDuringCreateOrderServerError: '创建订单时出错（服务器错误）',
        orderCreated: '订单已成功创建',
        changeToStatus: '更改为状态',
        idWithDots: 'ID: ',
        startDateWithDots: '开始日期: ',
        userWithDots: '用户: ',
        delivryWithDots: '送货: ',
        priceKiloWattPerHourWithDots: '千瓦时成本: ',
        priceCoilKiloWithDots: '每公斤线圈成本: ',
        priceProduction: '生产成本: ',
        totalPrice: '总成本: ',
        downloadInvoice: '下载发票',
        errorDuringDownloadInvoice: '下载发票时出错',
        orderStatusUpdated: '订单状态已成功更新',
        error404: '错误 404',
        pageNotFound: '您正在寻找的页面无法找到。',
        backToHome: '返回主页',
        numberOfOrder: '订单数量:',
        orderCancel: '取消的订单:',
        products: '产品',
        numberOfProducts: '产品数量:',
        avgProducts: '每个订单的平均数量:',
        weights: '重量',
        totalWeight: '总重量:',
        avgWeight: '平均重量:',
        time: '时间',
        totalTime: '总时间:',
        avgTime: '平均时间:',
        price: '成本',
        coilUsage: '线圈使用:',
        orderFor3Years: '每月订单（3 年）:',
        productFor3Years: '每月产品（3 年）:',
        january: '一月',
        february: '二月',
        march: '三月',
        april: '四月',
        may: '五月',
        june: '六月',
        july: '七月',
        august: '八月',
        september: '九月',
        october: '十月',
        november: '十一月',
        december: '十二月',
        versionManagement: '版本管理',
        major: '主要',
        minor: '次要',
        fix: '修复',
        addVersion: '添加版本',
        needFillDescriptionForThisVersion: '请填写版本描述',
        errorDuringCreateVersion: '创建版本时出错',
        versionCreated: '版本已成功创建',
        errorDuringGetCoil: '获取线圈时出错',
        errorDuringDeleteCoil: '删除线圈时出错',
        coilDeleted: '线圈已成功删除。',
        errorDuringCreateCoil: '创建线圈时出错',
        coilCreated: '线圈已成功创建',
        errorDuringEditCoil: '编辑线圈时出错',
        coilEdited: '线圈已成功编辑。',
        errorDuringGetOrders: '获取订单时出错。',
        pleaseRefreshPage: '请刷新页面（f5）以更新产品',
        errorDuringGetMessage: '获取消息时出错',
        errorDuringGetColors: '获取颜色时出错',
        errorDuringDeleteColors: '删除颜色时出错',
        colorDeleted: '颜色已成功删除',
        errorDuringEditColor: '编辑颜色时出错',
        colorEdited: '颜色已成功编辑',
        errorDuringGetRoles: '获取角色时出错',
        errorDuringGetStats: '获取统计数据时出错',
        stats: '统计数据',
        versions: '版本',
        orderDetail: '订单详情',
        pageNotFoundShort: '页面未找到',
        admin: '管理员',
        client: '客户',
        language: '语言',
        french: '法语',
        english: '英语',
        hindi: '印地语',
        chinese: '中文',
        spanish: '西班牙语',
        arabic: '阿拉伯语',
        bengali: '孟加拉语',
        portuguese: '葡萄牙语',
        russian: '俄语',
        urdu: '乌尔都语',
        newLanguage: '新语言',
    },
    es: {
        profit: "Beneficio",
        productsByPrinter: "Productos por impresora",
        productEditedSuccessfully: "Producto editado con éxito",
        selectCoil: "Seleccionar bobinas",
        cancel2: "Cancelar",
        numberObjetAvailable: "Número de objetos restantes",
        allClient: "Todos los clientes",
        allStatus: "Todos los estados",
        noOrderCorresponding: "No hay pedidos que coincidan con tu búsqueda",
        selectAState: "Selecciona un estado",
        errorDuringGetStates: "Error durante la recuperación de los estados",
        selectAFilament: "Seleccione un filamento",
        filamentWithDots: "Filamentos: ",
        typeFilamentCreated: "Tipo de filamento creado",
        errorDuringCreateTypeFilament: "Error durante la creación del tipo de filamento",
        typeFilamentDeleted: "Tipo de filamento eliminado",
        errorDuringDeleteFilamentType: "Error durante la eliminación del tipo de filamento",
        AlertDeleteTypeFilament: "¡Advertencia! Estás a punto de eliminar el tipo de filamento. ¿Estás seguro de que quieres eliminarlo?",
        typeFilamentEdited: "El tipo de filamento ha sido modificado",
        errorDuringEditingTypeFilament: "Error durante la edición del tipo de filamento",
        libelleCannotBeEmpty: "La etiqueta no puede estar vacía",
        noTypeFilament: "No hay tipo de filamento",
        errorDuringLoadFilamentType: "Error durante la carga de los tipos de filamento",
        label: "Etiqueta",
        newLabelTypeOfFilament: "Nueva etiqueta del tipo de filamento",
        filamentsType: "Tipos de filamento",
        youNeedToSelectABrand: "Necesita seleccionar una marca",
        selectABrand: "Seleccione una marca",
        brandWithDots: "Marca: ",
        AlertDeleteBrand: "¡Advertencia! Estás a punto de eliminar la marca. ¿Estás seguro de que quieres eliminarla?",
        brandDeleted: "Marca eliminada con éxito",
        errorDuringDeleteMarque: "Error durante la eliminación de la marca",
        noBrand: "Sin marca",
        brandEdited: "Marca editada con éxito",
        errorDuringEditBrand: "Error durante la modificación de la marca",
        brandCreated: "Marca creada con éxito",
        errorDuringCreateBrand: "Error durante la creación de la marca",
        needFillBrandName: "Por favor, introduzca el nombre de la marca",
        brandName: "Nombre de la marca",
        errorDuringLoadBrand: "Error al cargar las marcas",
        brands: "Las marcas",
        closeAction: "Cerrar",
        show3D: "Mostrar en 3D",
        showFailedPrint: "Mostrar impresiones fallidas",
        causeWithDots: "Causa: ",
        failedCreated: "\"Fallido\" creado con éxito",
        errorDuringCreatFailed: "Error durante la creación de \"fallido\"",
        needFillCause: "Por favor, introduzca la causa",
        causeOfFailed: "Causa del fallo",
        TimeInfZero: "El tiempo debe ser mayor que 0.",
        weightInfZero: "El peso debe ser mayor que 0.",
        needFillCoil: "Por favor, introduzca una bobina.",
        needFillPrinter: "Por favor, introduzca una impresora.",
        addAFailedObject: "Agregar un objeto fallido",
        consumption: "Consumo:",
        totalPriceKwhConsumption: "Costo total del consumo (en €):",
        consumptionKwh: "Consumo (en KWh):",
        avgTimeParPiece: "Tiempo promedio por pieza:",
        avgProductPriceParPiece: "Costo de producción promedio por pieza:",
        statsPrinter: "Estadísticas de la impresora",
        sort: "ordenar",
        chooseSort: "Elegir un orden",
        sortByNumber: "Ordenar por número",
        sortByWeight: "Ordenar por peso",
        asc: "Ascendente",
        desc: "Descendente",
        successSetCoilUndefective: "Bobina marcada como no defectuosa",
        errorDuringActiveInformations: "Error al recuperar información activa",
        errorDuringInformations: "Error al recuperar la información",
        informations: "La información",
        endDate: "Fecha de finalización",
        any: "ninguna",
        attentionAlertDeleteInformation: "¡Atención! Estás a punto de eliminar la información. ¿Estás seguro de que deseas eliminarla?",
        errorDuringDeleteInformation: "Error al eliminar la información",
        informationDeleted: "Información eliminada con éxito",
        messageCannotBeEmpty: "El mensaje no puede estar vacío",
        errorDuringEditInformation: "Error al editar la información",
        informationEdited: "Información editada con éxito",
        noInformation: "Sin información",
        informationMessage: "Mensaje de información",
        errorDuringCreateInformation: "Error al crear la información",
        informationCreate: "Información creada con éxito",
        chooseAPrinter: "Elige una impresora",
        printerEdited: "Impresora editada con éxito",
        errorDuringEditPrinter: "Error durante la edición de la impresora",
        printerDeleted: "Impresora eliminada con éxito",
        errorDuringDeletePrinter: "Error durante la eliminación de la impresora",
        attentionAlertDeletePrinter: "¡Atención! Estás a punto de eliminar la impresora ",
        attentionAlertDeletePrinterEnd: ". Esta acción es irreversible. ¿Estás seguro de que quieres eliminarla?",
        urlImgPrinter: "URL de la imagen de la impresora",
        printerCreated: "Impresora creada con éxito",
        errorDuringCreatePrinter: "Error durante la creación de la impresora",
        consommation: "Consumo (en vatios)",
        model: "Modelo",
        brand: "Marca",
        printers: "Impresoras",
        printerWithDot: "Impresora: ",
        errorDuringGetPrinters: "Error durante la recuperación de las impresoras",
        filling: 'Relleno',
        fillingWithDots: 'Relleno: ',
        productName: 'Nombre del producto',
        color: 'Color',
        colorWithDots: 'Color: ',
        selectAColor: 'Seleccione un color',
        addColor: 'Añadir un color',
        quantity: 'Cantidad',
        quantityWithDots: 'Cantidad: ',
        chooseAStl: 'Seleccionar un archivo STL',
        deleteAProduct: 'Eliminar el producto',
        coil: 'Bobina',
        coilWithDots: 'Bobina: ',
        remainingWeight: 'Peso restante:',
        inputProductName: 'Ingrese el nombre del producto',
        inputQuantity: 'Ingrese la cantidad',
        presumedDefective: 'Presunto defectuoso',
        errorSetCoilDefective: 'Error al marcar la bobina como defectuosa',
        successSetCoilDefective: 'Bobina marcada como defectuosa',
        newCoilWeight: 'Nuevo peso de la bobina',
        orderBy: 'Ordenado por: ',
        todo: 'Por hacer',
        wip: 'En progreso',
        ended: 'Terminado',
        endedAndPaid: 'Terminado y pagado',
        cancel: 'Cancelado',
        todoPrint: 'Por imprimir',
        printing: 'Imprimiendo',
        printed: 'Impreso',
        printCancel: 'Cancelado',
        delivryAddressWithDots: 'Dirección de entrega: ',
        orderDate: 'Fecha del pedido: ',
        errorDuringDeleteMessage: 'Error al eliminar el mensaje',
        successDeleteMessage: 'El mensaje ha sido eliminado correctamente',
        labelCannotBeEmpty: 'La etiqueta no puede estar vacía',
        weightWithDots: 'Peso: ',
        timeWithDots: 'Tiempo: ',
        chooseACoil: 'Seleccione una bobina',
        downloadStl: 'Descargar STL',
        errorDuringGetOrder: 'Error al recuperar el pedido',
        errorDuringServerConnexion: 'Error al conectar con el servidor',
        productStatusUpdated: 'El estado del producto ha sido actualizado correctamente',
        needFillAllFields: 'Por favor, complete todos los campos',
        weightNeedBeNumber: 'El peso debe ser un número',
        weightAndTimeUpdated: 'El peso y el tiempo del pedido han sido actualizados correctamente',
        cancelProduct: 'Cancelar el producto',
        changeToPrinting: 'Cambiar al estado "Imprimiendo"',
        version: 'Versión',
        versionDescription: 'Descripción de la versión',
        errorDuringDeleteVersion: 'Error al eliminar la versión',
        versionDeleted: 'Versión eliminada con éxito',
        errorDuringEditVersion: 'Error al editar la versión',
        versionEdited: 'Versión editada con éxito',
        createAColor: 'Crear un color',
        colorName: 'Nombre del color',
        validate: 'Validar',
        cancelAction: 'Cancelar',
        colorNameCannotBeEmpty: 'El nombre del color no puede estar vacío',
        errorDuringCreateColor: 'Error al crear el color',
        createSucces: 'Creado con éxito',
        erreurDuringFillFields: 'Error al completar los campos',
        continue: 'Continuar',
        confirmDelete: '¿Confirmar eliminación?',
        attentionAlertDeleteCoil: '¡Atención! Actualmente quedan ',
        attentionAlertDeleteCoilEnd: 'gramos en esta bobina según nuestro stock. Esta acción es irreversible. ¿Está seguro de que desea eliminarla?',
        deleteAction: 'Eliminar',
        attentionAlertDeleteColor: '¡Atención! Está a punto de eliminar el color ',
        attentionAlertDeleteColorEnd: '. Esta acción es irreversible. ¿Está seguro de que desea eliminarlo?',
        colors: 'Colores',
        coils: 'Bobinas',
        createAnOrder: 'Crear un pedido',
        myOrders: 'Mis pedidos',
        orders: 'Pedidos',
        ordersOnly: 'Pedidos',
        register: 'Registrarse',
        messages: 'Mensajes',
        contact: 'Contacto',
        logout: 'Cerrar sesión',
        login: 'Iniciar sesión',
        versionsOnly: 'Versiones',
        myAccount: 'Mi cuenta',
        youAreDisconnected: 'Está desconectado',
        noNeedFillFormFull: 'No es necesario completar todo el formulario.',
        ifChangeMailOnlyMailWillChange: 'Si desea cambiar su correo electrónico, solo ingrese el campo de correo electrónico.',
        modifyMyInformation: 'Modificar mi información',
        newMail: 'Nuevo correo electrónico',
        newPassword: 'Nueva contraseña',
        confirmPassword: 'Confirmar contraseña',
        newDelivreryAddress: 'Nueva dirección de entrega',
        editAction: 'Editar',
        youNeedToBeConnectedToAccessThisPage: 'Debe estar conectado para acceder a esta página',
        youNeedToFillOneField: 'Debe completar al menos un campo',
        passwordNotMatch: 'Las contraseñas no coinciden',
        personalInfoEditNeedToReconnect: 'Información personal editada con éxito\nPor favor, vuelva a iniciar sesión',
        weightInGrammes: 'Peso en gramos',
        addAction: 'Añadir',
        statsPerColor: 'Estadísticas por color',
        bobineList: 'Lista de bobinas',
        filter: 'Filtrar: ',
        all: 'Todos',
        youNeedToBeAdminToAccessThisPage: 'Debe ser administrador para acceder a esta página',
        remeningWeightInfZero: 'El peso restante debe ser mayor a 0',
        youNeedToSelectAColor: 'Debe seleccionar un color',
        showMore: 'Mostrar más',
        noMessage: 'Sin mensajes',
        inscriptionForm: 'Formulario de inscripción',
        email: 'Correo electrónico',
        yourEmail: 'Su correo electrónico',
        password: 'Contraseña',
        yourPassword: 'Su contraseña',
        role: 'Rol',
        chooseARole: 'Seleccione un rol',
        chooseALanguage: 'Seleccione un idioma',
        delivryAdresse: 'Dirección de entrega',
        delivry: 'Entrega',
        registerMe: 'Registrarme',
        errorDuringRegisterUser: 'Error al registrar el usuario',
        userRegisterSuccess: 'Usuario registrado con éxito',
        message: 'Mensaje',
        yourMessage: 'Su mensaje',
        youDontHaveOrder: 'No tiene ningún pedido',
        leftToPay: 'Restante por pagar',
        connection: 'Conexión',
        firstname: 'Nombre',
        youtFirstname: 'Su nombre',
        lastname: 'Apellido',
        yourLastname: 'Su apellido',
        send: 'Enviar',
        errorDuringSendMessage: 'Error al enviar el mensaje',
        messageSent: 'Mensaje enviado con éxito',
        createOrder: 'Crear un pedido',
        createOrderFor: 'Crear un pedido para',
        selectAClient: 'Seleccione un cliente',
        product: 'Producto',
        orderName: 'Nombre del pedido',
        inputOrderName: 'Ingrese el nombre del pedido',
        confirmOrder: 'Confirmar el pedido',
        requireMinOneProduct: 'Debe tener al menos un producto',
        needFillCommandeName: 'Debe ingresar un nombre para el pedido',
        youNeedSelectAClient: 'Debe seleccionar un cliente',
        youNeedToAddMinOneProduct: 'Debe añadir al menos un producto',
        youNeedFillAllFieldsOfProduct: 'Debe completar todos los campos de los productos',
        errorDuringCreateOrder: 'Error al crear el pedido',
        errorDuringCreateOrderServerError: 'Error al crear el pedido (error del servidor)',
        orderCreated: 'Pedido creado con éxito',
        changeToStatus: 'Cambiar al estado',
        idWithDots: 'ID: ',
        startDateWithDots: 'Fecha de inicio: ',
        userWithDots: 'Usuario: ',
        delivryWithDots: 'Entrega: ',
        priceKiloWattPerHourWithDots: 'Costo KW/H: ',
        priceCoilKiloWithDots: 'Costo por kilo de bobina: ',
        priceProduction: 'Costo de producción: ',
        totalPrice: 'Costo total: ',
        downloadInvoice: 'Descargar factura',
        errorDuringDownloadInvoice: 'Error al descargar la factura',
        orderStatusUpdated: 'El estado del pedido ha sido actualizado correctamente',
        error404: 'Error 404',
        pageNotFound: 'La página que busca no se encuentra.',
        backToHome: 'Volver a la página de inicio',
        numberOfOrder: 'Número de pedidos:',
        orderCancel: 'Pedido cancelado:',
        products: 'Productos',
        numberOfProducts: 'Número de productos:',
        avgProducts: 'Promedio por pedido:',
        weights: 'Pesos',
        totalWeight: 'Peso total:',
        avgWeight: 'Peso promedio:',
        time: 'Tiempo',
        totalTime: 'Tiempo total:',
        avgTime: 'Tiempo promedio:',
        price: 'Costos',
        coilUsage: 'Uso de bobinas:',
        orderFor3Years: 'Pedidos por mes (3 años):',
        productFor3Years: 'Productos por mes (3 años):',
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre',
        versionManagement: 'Gestión de versiones',
        major: 'Mayor',
        minor: 'Menor',
        fix: 'Corrección',
        addVersion: 'Añadir una versión',
        needFillDescriptionForThisVersion: 'Por favor, complete la descripción para la versión',
        errorDuringCreateVersion: 'Error al crear la versión',
        versionCreated: 'Versión creada con éxito',
        errorDuringGetCoil: 'Error al obtener las bobinas',
        errorDuringDeleteCoil: 'Error al eliminar la bobina',
        coilDeleted: 'Bobina eliminada con éxito.',
        errorDuringCreateCoil: 'Error al crear la bobina',
        coilCreated: 'Bobina creada con éxito',
        errorDuringEditCoil: 'Error al editar la bobina',
        coilEdited: 'Bobina editada con éxito.',
        errorDuringGetOrders: 'Error al obtener los pedidos.',
        pleaseRefreshPage: 'Por favor, actualice la página (f5) para actualizar el producto',
        errorDuringGetMessage: 'Error al obtener los mensajes',
        errorDuringGetColors: 'Error al obtener los colores',
        errorDuringDeleteColors: 'Error al eliminar el color',
        colorDeleted: 'Color eliminado con éxito',
        errorDuringEditColor: 'Error al editar el color',
        colorEdited: 'Color editado con éxito',
        errorDuringGetRoles: 'Error al obtener los roles',
        errorDuringGetStats: 'Error al obtener las estadísticas',
        stats: 'Estadísticas',
        versions: 'Versiones',
        orderDetail: 'Detalle del pedido',
        pageNotFoundShort: 'Página no encontrada',
        admin: 'Administrador',
        client: 'Cliente',
        language: 'Idioma',
        french: 'Francés',
        english: 'Inglés',
        hindi: 'Hindi',
        chinese: 'Chino',
        spanish: 'Español',
        arabic: 'Árabe',
        bengali: 'Bengalí',
        portuguese: 'Portugués',
        russian: 'Ruso',
        urdu: 'Urdu',
        newLanguage: 'Nuevo idioma',
    },
    ar: {
        profit: "الربح",
        productsByPrinter: "المنتجات حسب الطابعة",
        productEditedSuccessfully: "تم تعديل المنتج بنجاح",
        selectCoil: "اختر البكرات",
        cancel2: "إلغاء",
        numberObjetAvailable: "عدد الكائنات المتبقية",
        allClient: "جميع العملاء",
        allStatus: "جميع الحالات",
        noOrderCorresponding: "لا توجد طلبات مطابقة لبحثك",
        selectAState: "اختر ولاية",
        errorDuringGetStates: "خطأ أثناء استرجاع الولايات",
        selectAFilament: "اختر خيطًا",
        filamentWithDots: "الخيوط: ",
        typeFilamentCreated: "تم إنشاء نوع الخيط",
        errorDuringCreateTypeFilament: "خطأ أثناء إنشاء نوع الخيط",
        typeFilamentDeleted: "تم حذف نوع الخيط",
        errorDuringDeleteFilamentType: "خطأ أثناء حذف نوع الخيط",
        AlertDeleteTypeFilament: "تحذير! أنت على وشك حذف نوع الخيط. هل أنت متأكد أنك تريد حذفه؟",
        typeFilamentEdited: "تم تعديل نوع الخيط",
        errorDuringEditingTypeFilament: "خطأ أثناء تعديل نوع الخيط",
        libelleCannotBeEmpty: "لا يمكن أن تكون التسمية فارغة",
        noTypeFilament: "لا يوجد نوع خيط",
        errorDuringLoadFilamentType: "خطأ أثناء تحميل أنواع الخيوط",
        label: "تسمية",
        newLabelTypeOfFilament: "تسمية جديدة لنوع الخيط",
        filamentsType: "أنواع الخيوط",
        youNeedToSelectABrand: "تحتاج إلى اختيار علامة تجارية",
        selectABrand: "اختر علامة تجارية",
        brandWithDots: "علامة تجارية: ",
        AlertDeleteBrand: "تحذير! أنت على وشك حذف العلامة التجارية. هل أنت متأكد أنك تريد حذفها؟",
        brandDeleted: "تم حذف العلامة التجارية بنجاح",
        errorDuringDeleteMarque: "حدث خطأ أثناء حذف العلامة التجارية",
        noBrand: "لا توجد علامة تجارية",
        brandEdited: "تم تعديل العلامة التجارية بنجاح",
        errorDuringEditBrand: "خطأ أثناء تعديل العلامة التجارية",
        brandCreated: "تم إنشاء العلامة التجارية بنجاح",
        errorDuringCreateBrand: "حدث خطأ أثناء إنشاء العلامة التجارية",
        needFillBrandName: "يرجى إدخال اسم العلامة التجارية",
        brandName: "اسم العلامة التجارية",
        errorDuringLoadBrand: "خطأ أثناء تحميل العلامات التجارية",
        brands: "العلامات التجارية",
        closeAction: "إغلاق",
        show3D: "عرض ثلاثي الأبعاد",
        showFailedPrint: "عرض الطباعة الفاشلة",
        causeWithDots: "السبب: ",
        failedCreated: "\"فشل\" تم إنشاؤه بنجاح",
        errorDuringCreatFailed: "خطأ أثناء إنشاء \"الفشل\"",
        needFillCause: "يرجى إدخال السبب",
        causeOfFailed: "سبب الفشل",
        TimeInfZero: "يجب أن يكون الوقت أكبر من 0.",
        weightInfZero: "يجب أن يكون الوزن أكبر من 0.",
        needFillCoil: "يرجى إدخال ملف.",
        needFillPrinter: "يرجى إدخال طابعة.",
        addAFailedObject: "إضافة كائن فاشل",
        consumption: "الاستهلاك:",
        totalPriceKwhConsumption: "التكلفة الإجمالية للاستهلاك (بال€):",
        consumptionKwh: "الاستهلاك (بالكيلوواط ساعة):",
        avgTimeParPiece: "متوسط الوقت لكل قطعة:",
        avgProductPriceParPiece: "متوسط تكلفة الإنتاج لكل قطعة:",
        statsPrinter: "إحصائيات الطابعة",
        sort: "فرز",
        chooseSort: "اختر الفرز",
        sortByNumber: "فرز حسب الرقم",
        sortByWeight: "فرز حسب الوزن",
        asc: "تصاعدي",
        desc: "تنازلي",
        successSetCoilUndefective: "تم وضع علامة على الملف على أنه غير معيب",
        errorDuringActiveInformations: "خطأ أثناء استرجاع المعلومات النشطة",
        errorDuringInformations: "خطأ أثناء استرجاع المعلومات",
        informations: "المعلومات",
        endDate: "تاريخ الانتهاء",
        any: "لا شيء",
        attentionAlertDeleteInformation: "تحذير! أنت على وشك حذف المعلومات. هل أنت متأكد أنك تريد حذفها؟",
        errorDuringDeleteInformation: "خطأ أثناء حذف المعلومات",
        informationDeleted: "تم حذف المعلومات بنجاح",
        messageCannotBeEmpty: "الرسالة لا يمكن أن تكون فارغة",
        errorDuringEditInformation: "خطأ أثناء تعديل المعلومات",
        informationEdited: "تم تعديل المعلومات بنجاح",
        noInformation: "لا توجد معلومات",
        informationMessage: "رسالة المعلومات",
        errorDuringCreateInformation: "خطأ أثناء إنشاء المعلومات",
        informationCreate: "تم إنشاء المعلومات بنجاح",
        chooseAPrinter: "اختر طابعة",
        printerEdited: "تم تعديل الطابعة بنجاح",
        errorDuringEditPrinter: "خطأ أثناء تعديل الطابعة",
        printerDeleted: "تم حذف الطابعة بنجاح",
        errorDuringDeletePrinter: "خطأ أثناء حذف الطابعة",
        attentionAlertDeletePrinter: "انتباه! أنت على وشك حذف الطابعة ",
        attentionAlertDeletePrinterEnd: ". هذا الإجراء لا يمكن التراجع عنه. هل أنت متأكد أنك تريد حذفها؟",
        urlImgPrinter: "عنوان URL لصورة الطابعة",
        printerCreated: "تم إنشاء الطابعة بنجاح",
        errorDuringCreatePrinter: "خطأ أثناء إنشاء الطابعة",
        consommation: "استهلاك (بالواط)",
        model: "نموذج",
        brand: "علامة تجارية",
        printers: "الطابعات",
        printerWithDot: "طابعة: ",
        errorDuringGetPrinters: "خطأ أثناء استرجاع الطابعات",
        filling: 'تعبئة',
        fillingWithDots: 'تعبئة: ',
        productName: 'اسم المنتج',
        color: 'اللون',
        colorWithDots: 'اللون: ',
        selectAColor: 'اختر لونًا',
        addColor: 'أضف لونًا',
        quantity: 'الكمية',
        quantityWithDots: 'الكمية: ',
        chooseAStl: 'اختر ملف STL',
        deleteAProduct: 'احذف المنتج',
        coil: 'ملف',
        coilWithDots: 'ملف: ',
        remainingWeight: 'الوزن المتبقي:',
        inputProductName: 'أدخل اسم المنتج',
        inputQuantity: 'أدخل الكمية',
        presumedDefective: 'يفترض أنها معيبة',
        errorSetCoilDefective: 'خطأ في تعيين الملف كمعيب',
        successSetCoilDefective: 'تم تعيين الملف كمعيب بنجاح',
        newCoilWeight: 'وزن الملف الجديد',
        orderBy: 'ترتيب بواسطة: ',
        todo: 'لأفعل',
        wip: 'قيد التنفيذ',
        ended: 'منتهي',
        endedAndPaid: 'منتهي ومدفوع',
        cancel: 'ملغى',
        todoPrint: 'للطباعة',
        printing: 'طباعة',
        printed: 'مطبوع',
        printCancel: 'إلغاء الطباعة',
        delivryAddressWithDots: 'عنوان التسليم: ',
        orderDate: 'تاريخ الطلب: ',
        errorDuringDeleteMessage: 'خطأ أثناء حذف الرسالة',
        successDeleteMessage: 'تم حذف الرسالة بنجاح',
        labelCannotBeEmpty: 'لا يمكن أن تكون التسمية فارغة',
        weightWithDots: 'الوزن: ',
        timeWithDots: 'الوقت: ',
        chooseACoil: 'اختر ملفًا',
        downloadStl: 'تنزيل STL',
        errorDuringGetOrder: 'خطأ أثناء استرداد الطلب',
        errorDuringServerConnexion: 'خطأ أثناء الاتصال بالخادم',
        productStatusUpdated: 'تم تحديث حالة المنتج بنجاح',
        needFillAllFields: 'يرجى ملء جميع الحقول',
        weightNeedBeNumber: 'يجب أن يكون الوزن رقمًا',
        weightAndTimeUpdated: 'تم تحديث وزن ووقت الطلب بنجاح',
        cancelProduct: 'إلغاء المنتج',
        changeToPrinting: 'تغيير الحالة إلى "طباعة"',
        version: 'الإصدار',
        versionDescription: 'وصف الإصدار',
        errorDuringDeleteVersion: 'خطأ أثناء حذف الإصدار',
        versionDeleted: 'تم حذف الإصدار بنجاح',
        errorDuringEditVersion: 'خطأ أثناء تحرير الإصدار',
        versionEdited: 'تم تحرير الإصدار بنجاح',
        createAColor: 'إنشاء لون',
        colorName: 'اسم اللون',
        validate: 'تأكيد',
        cancelAction: 'إلغاء',
        colorNameCannotBeEmpty: 'لا يمكن أن يكون اسم اللون فارغًا',
        errorDuringCreateColor: 'خطأ أثناء إنشاء اللون',
        createSucces: 'تم الإنشاء بنجاح',
        erreurDuringFillFields: 'خطأ في ملء الحقول',
        continue: 'استمر',
        confirmDelete: 'تأكيد الحذف؟',
        attentionAlertDeleteCoil: 'تحذير! يوجد حاليًا ',
        attentionAlertDeleteCoilEnd: 'جرام على هذا الملف وفقًا لمخزوننا. هذا الإجراء لا يمكن التراجع عنه. هل أنت متأكد من أنك تريد حذفه؟',
        deleteAction: 'حذف',
        attentionAlertDeleteColor: 'تحذير! أنت على وشك حذف اللون ',
        attentionAlertDeleteColorEnd: '. هذا الإجراء لا يمكن التراجع عنه. هل أنت متأكد من أنك تريد حذفه؟',
        colors: 'الألوان',
        coils: 'الملفات',
        createAnOrder: 'إنشاء طلب',
        myOrders: 'طلباتي',
        orders: 'الطلبات',
        ordersOnly: 'الطلبات',
        register: 'تسجيل',
        messages: 'الرسائل',
        contact: 'اتصل',
        logout: 'تسجيل خروج',
        login: 'تسجيل دخول',
        versionsOnly: 'الإصدارات',
        myAccount: 'حسابي',
        youAreDisconnected: 'تم قطع الاتصال',
        noNeedFillFormFull: 'لا تحتاج إلى ملء النموذج بالكامل.',
        ifChangeMailOnlyMailWillChange: 'إذا كنت ترغب في تغيير بريدك الإلكتروني، فقط أدخل حقل البريد الإلكتروني.',
        modifyMyInformation: 'تعديل معلوماتي',
        newMail: 'بريد إلكتروني جديد',
        newPassword: 'كلمة مرور جديدة',
        confirmPassword: 'تأكيد كلمة المرور',
        newDelivreryAddress: 'عنوان تسليم جديد',
        editAction: 'تعديل',
        youNeedToBeConnectedToAccessThisPage: 'يجب أن تكون متصلاً للوصول إلى هذه الصفحة',
        youNeedToFillOneField: 'يجب عليك ملء حقل واحد على الأقل',
        passwordNotMatch: 'كلمات المرور غير متطابقة',
        personalInfoEditNeedToReconnect: 'تم تعديل المعلومات الشخصية بنجاح\nيرجى إعادة الاتصال',
        weightInGrammes: 'الوزن بالجرام',
        addAction: 'إضافة',
        statsPerColor: 'إحصائيات حسب اللون',
        bobineList: 'قائمة الملفات',
        filter: 'تصفية: ',
        all: 'الكل',
        youNeedToBeAdminToAccessThisPage: 'يجب أن تكون مسؤولًا للوصول إلى هذه الصفحة',
        remeningWeightInfZero: 'يجب أن يكون الوزن المتبقي أكبر من 0',
        youNeedToSelectAColor: 'يجب عليك اختيار لون',
        showMore: 'عرض المزيد',
        noMessage: 'لا توجد رسائل',
        inscriptionForm: 'نموذج التسجيل',
        email: 'البريد الإلكتروني',
        yourEmail: 'بريدك الإلكتروني',
        password: 'كلمة المرور',
        yourPassword: 'كلمة المرور الخاصة بك',
        role: 'الدور',
        chooseARole: 'اختر دورًا',
        chooseALanguage: 'اختر لغة',
        delivryAdresse: 'عنوان التسليم',
        delivry: 'التسليم',
        registerMe: 'سجلني',
        errorDuringRegisterUser: 'خطأ أثناء تسجيل المستخدم',
        userRegisterSuccess: 'تم تسجيل المستخدم بنجاح',
        message: 'رسالة',
        yourMessage: 'رسالتك',
        youDontHaveOrder: 'ليس لديك أي طلبات',
        leftToPay: 'المتبقي للدفع',
        connection: 'الاتصال',
        firstname: 'الاسم الأول',
        youtFirstname: 'اسمك الأول',
        lastname: 'اسم العائلة',
        yourLastname: 'اسم عائلتك',
        send: 'إرسال',
        errorDuringSendMessage: 'خطأ أثناء إرسال الرسالة',
        messageSent: 'تم إرسال الرسالة بنجاح',
        createOrder: 'إنشاء طلب',
        createOrderFor: 'إنشاء طلب ل',
        selectAClient: 'اختر عميلًا',
        product: 'منتج',
        orderName: 'اسم الطلب',
        inputOrderName: 'أدخل اسم الطلب',
        confirmOrder: 'تأكيد الطلب',
        requireMinOneProduct: 'يجب أن يكون لديك منتج واحد على الأقل',
        needFillCommandeName: 'يجب عليك إدخال اسم الطلب',
        youNeedSelectAClient: 'يجب عليك اختيار عميل',
        youNeedToAddMinOneProduct: 'يجب عليك إضافة منتج واحد على الأقل',
        youNeedFillAllFieldsOfProduct: 'يجب عليك ملء جميع حقول المنتجات',
        errorDuringCreateOrder: 'خطأ أثناء إنشاء الطلب',
        errorDuringCreateOrderServerError: 'خطأ أثناء إنشاء الطلب (خطأ في الخادم)',
        orderCreated: 'تم إنشاء الطلب بنجاح',
        changeToStatus: 'تغيير الحالة إلى',
        idWithDots: 'المعرف: ',
        startDateWithDots: 'تاريخ البدء: ',
        userWithDots: 'المستخدم: ',
        delivryWithDots: 'التسليم: ',
        priceKiloWattPerHourWithDots: 'تكلفة الكيلوواط/ساعة: ',
        priceCoilKiloWithDots: 'تكلفة الكيلوغرام الواحد من الملف: ',
        priceProduction: 'تكلفة الإنتاج: ',
        totalPrice: 'التكلفة الإجمالية: ',
        downloadInvoice: 'تنزيل الفاتورة',
        errorDuringDownloadInvoice: 'خطأ أثناء تنزيل الفاتورة',
        orderStatusUpdated: 'تم تحديث حالة الطلب بنجاح',
        error404: 'خطأ 404',
        pageNotFound: 'الصفحة التي تبحث عنها غير موجودة.',
        backToHome: 'العودة إلى الصفحة الرئيسية',
        numberOfOrder: 'عدد الطلبات:',
        orderCancel: 'الطلبات الملغاة:',
        products: 'المنتجات',
        numberOfProducts: 'عدد المنتجات:',
        avgProducts: 'متوسط العدد لكل طلب:',
        weights: 'الأوزان',
        totalWeight: 'الوزن الكلي:',
        avgWeight: 'متوسط الوزن:',
        time: 'الوقت',
        totalTime: 'الوقت الكلي:',
        avgTime: 'متوسط الوقت:',
        price: 'التكاليف',
        coilUsage: 'استخدام الملفات:',
        orderFor3Years: 'الطلبات لكل شهر (3 سنوات):',
        productFor3Years: 'المنتجات لكل شهر (3 سنوات):',
        january: 'يناير',
        february: 'فبراير',
        march: 'مارس',
        april: 'أبريل',
        may: 'مايو',
        june: 'يونيو',
        july: 'يوليو',
        august: 'أغسطس',
        september: 'سبتمبر',
        october: 'أكتوبر',
        november: 'نوفمبر',
        december: 'ديسمبر',
        versionManagement: 'إدارة الإصدارات',
        major: 'رئيسي',
        minor: 'ثانوي',
        fix: 'إصلاح',
        addVersion: 'إضافة إصدار',
        needFillDescriptionForThisVersion: 'يرجى تقديم وصف للإصدار',
        errorDuringCreateVersion: 'خطأ أثناء إنشاء الإصدار',
        versionCreated: 'تم إنشاء الإصدار بنجاح',
        errorDuringGetCoil: 'خطأ أثناء استرداد الملفات',
        errorDuringDeleteCoil: 'خطأ أثناء حذف الملف',
        coilDeleted: 'تم حذف الملف بنجاح.',
        errorDuringCreateCoil: 'خطأ أثناء إنشاء الملف',
        coilCreated: 'تم إنشاء الملف بنجاح',
        errorDuringEditCoil: 'خطأ أثناء تحرير الملف',
        coilEdited: 'تم تحرير الملف بنجاح.',
        errorDuringGetOrders: 'خطأ أثناء استرداد الطلبات.',
        pleaseRefreshPage: 'يرجى تحديث الصفحة (f5) لتحديث المنتج',
        errorDuringGetMessage: 'خطأ أثناء استرداد الرسائل',
        errorDuringGetColors: 'خطأ أثناء استرداد الألوان',
        errorDuringDeleteColors: 'خطأ أثناء حذف اللون',
        colorDeleted: 'تم حذف اللون بنجاح',
        errorDuringEditColor: 'خطأ أثناء تحرير اللون',
        colorEdited: 'تم تحرير اللون بنجاح',
        errorDuringGetRoles: 'خطأ أثناء استرداد الأدوار',
        errorDuringGetStats: 'خطأ أثناء استرداد الإحصائيات',
        stats: 'الإحصائيات',
        versions: 'الإصدارات',
        orderDetail: 'تفاصيل الطلب',
        pageNotFoundShort: 'الصفحة غير موجودة',
        admin: 'مدير',
        client: 'عميل',
        language: 'اللغة',
        french: 'فرنسي',
        english: 'إنجليزي',
        hindi: 'هندي',
        chinese: 'صيني',
        spanish: 'إسباني',
        arabic: 'عربي',
        bengali: 'بنغالي',
        portuguese: 'برتغالي',
        russian: 'روسي',
        urdu: 'أردو',
        newLanguage: 'لغة جديدة',
    },
    bn: {
        profit: "লাভ",
        productsByPrinter: "প্রিন্টার দ্বারা পণ্য",
        productEditedSuccessfully: "পণ্যটি সফলভাবে সম্পাদিত হয়েছে",
        selectCoil: "কয়েলগুলি নির্বাচন করুন",
        cancel2: "বাতিল করুন",
        numberObjetAvailable: "বাকি বস্তুগুলির সংখ্যা",
        allClient: "সব গ্রাহক",
        allStatus: "সব অবস্থা",
        noOrderCorresponding: "আপনার অনুসন্ধানের সাথে কোনো অর্ডার মেলেনি",
        selectAState: "একটি রাজ্য নির্বাচন করুন",
        errorDuringGetStates: "অবস্থা পুনরুদ্ধারের সময় ত্রুটি",
        selectAFilament: "একটি ফিলামেন্ট নির্বাচন করুন",
        filamentWithDots: "ফিলামেন্টস: ",
        typeFilamentCreated: "ফিলামেন্টের ধরন তৈরি হয়েছে",
        errorDuringCreateTypeFilament: "ফিলামেন্টের ধরন তৈরির সময় ত্রুটি",
        typeFilamentDeleted: "ফিলামেন্টের ধরন মুছে ফেলা হয়েছে",
        errorDuringDeleteFilamentType: "ফিলামেন্টের ধরন মুছে ফেলার সময় ত্রুটি",
        AlertDeleteTypeFilament: "সতর্কতা! আপনি ফিলামেন্টের ধরনটি মুছে ফেলতে চলেছেন। আপনি কি এটি মুছে ফেলতে নিশ্চিত?",
        typeFilamentEdited: "ফিলামেন্টের ধরনটি সম্পাদিত হয়েছে",
        errorDuringEditingTypeFilament: "ফিলামেন্টের ধরন সম্পাদনা করার সময় ত্রুটি",
        libelleCannotBeEmpty: "লেবেলটি খালি হতে পারে না",
        noTypeFilament: "কোনও ফিলামেন্টের ধরন নেই",
        errorDuringLoadFilamentType: "ফিলামেন্টের ধরনগুলি লোড করার সময় ত্রুটি",
        label: "লেবেল",
        newLabelTypeOfFilament: "ফিলামেন্টের ধরনের নতুন লেবেল",
        filamentsType: "ফিলামেন্টের ধরনগুলি",
        youNeedToSelectABrand: "আপনাকে একটি ব্র্যান্ড নির্বাচন করতে হবে",
        selectABrand: "একটি ব্র্যান্ড নির্বাচন করুন",
        brandWithDots: "ব্র্যান্ড: ",
        AlertDeleteBrand: "সতর্কতা! আপনি ব্র্যান্ডটি মুছে ফেলার প্রক্রিয়ায় আছেন। আপনি কি এটি মুছে ফেলতে চান?",
        brandDeleted: "ব্র্যান্ড সফলভাবে মুছে ফেলা হয়েছে",
        errorDuringDeleteMarque: "ব্র্যান্ড মুছে ফেলার সময় ত্রুটি",
        noBrand: "কোনও ব্র্যান্ড নেই",
        brandEdited: "ব্র্যান্ড সফলভাবে সম্পাদিত হয়েছে",
        errorDuringEditBrand: "ব্র্যান্ড সম্পাদনা করার সময় ত্রুটি",
        brandCreated: "ব্র্যান্ড সফলভাবে তৈরি হয়েছে",
        errorDuringCreateBrand: "ব্র্যান্ড তৈরি করার সময় ত্রুটি",
        needFillBrandName: "দয়া করে ব্র্যান্ডের নাম লিখুন",
        brandName: "ব্র্যান্ড নাম",
        errorDuringLoadBrand: "ব্র্যান্ডগুলি লোড করার সময় ত্রুটি",
        brands: "ব্র্যান্ডগুলি",
        closeAction: "বন্ধ করুন",
        show3D: "3D তে দেখান",
        showFailedPrint: "ব্যর্থ প্রিন্টগুলি দেখান",
        causeWithDots: "কারণ: ",
        failedCreated: "\"ব্যর্থ\" সফলভাবে তৈরি হয়েছে",
        errorDuringCreatFailed: "\"ব্যর্থ\" তৈরির সময় ত্রুটি",
        needFillCause: "অনুগ্রহ করে কারণ লিখুন",
        causeOfFailed: "ব্যর্থতার কারণ",
        TimeInfZero: "সময় 0 এর বেশি হতে হবে।",
        weightInfZero: "ওজন 0 এর বেশি হতে হবে।",
        needFillCoil: "একটি কুণ্ডলী লিখুন।",
        needFillPrinter: "একটি প্রিন্টার লিখুন।",
        addAFailedObject: "একটি ব্যর্থ বস্তু যোগ করুন",
        consumption: "খরচ:",
        totalPriceKwhConsumption: "খরচের মোট খরচ (€):",
        consumptionKwh: "খরচ (কিলোওয়াট ঘণ্টায়):",
        avgTimeParPiece: "প্রতি টুকরো গড় সময়:",
        avgProductPriceParPiece: "প্রতি টুকরো গড় উৎপাদন খরচ:",
        statsPrinter: "প্রিন্টারের পরিসংখ্যান",
        sort: "সাজান",
        chooseSort: "একটি বাছাই চয়ন করুন",
        sortByNumber: "সংখ্যা দ্বারা সাজান",
        sortByWeight: "ওজন দ্বারা সাজান",
        asc: "আরোহী",
        desc: "অবরোহী",
        successSetCoilUndefective: "কয়েলটিকে ত্রুটিহীন হিসাবে চিহ্নিত করা হয়েছে",
        errorDuringActiveInformations: "সক্রিয় তথ্য পুনরুদ্ধারে ত্রুটি",
        errorDuringInformations: "তথ্য পুনরুদ্ধারে ত্রুটি",
        informations: "তথ্য",
        endDate: "সমাপ্তির তারিখ",
        any: "কিছুই না",
        attentionAlertDeleteInformation: "সতর্কতা! আপনি তথ্য মুছে ফেলার প্রক্রিয়ায় আছেন। আপনি কি এটি মুছে ফেলতে চান?",
        errorDuringDeleteInformation: "তথ্য মুছে ফেলার সময় ত্রুটি",
        informationDeleted: "তথ্য সফলভাবে মুছে ফেলা হয়েছে",
        messageCannotBeEmpty: "বার্তাটি খালি হতে পারে না",
        errorDuringEditInformation: "তথ্য সম্পাদনার সময় ত্রুটি",
        informationEdited: "তথ্য সফলভাবে সম্পাদিত হয়েছে",
        noInformation: "কোনও তথ্য নেই",
        informationMessage: "তথ্যের বার্তা",
        errorDuringCreateInformation: "তথ্য তৈরি করার সময় ত্রুটি",
        informationCreate: "তথ্য সফলভাবে তৈরি হয়েছে",
        chooseAPrinter: "একটি প্রিন্টার নির্বাচন করুন",
        printerEdited: "প্রিন্টার সফলভাবে সম্পাদিত হয়েছে",
        errorDuringEditPrinter: "প্রিন্টার সম্পাদনার সময় ত্রুটি",
        printerDeleted: "প্রিন্টার সফলভাবে মুছে ফেলা হয়েছে",
        errorDuringDeletePrinter: "প্রিন্টার মুছে ফেলার সময় ত্রুটি",
        attentionAlertDeletePrinter: "সতর্কতা! আপনি প্রিন্টার মুছে ফেলতে যাচ্ছেন ",
        attentionAlertDeletePrinterEnd: ". এই পদক্ষেপ অপরিবর্তনীয়। আপনি কি এটি মুছে ফেলতে নিশ্চিত?",
        urlImgPrinter: "প্রিন্টারের চিত্র URL",
        printerCreated: "প্রিন্টার সফলভাবে তৈরি হয়েছে",
        errorDuringCreatePrinter: "প্রিন্টার তৈরি করার সময় ত্রুটি",
        consommation: "ব্যবহার (ওয়াটে)",
        model: "মডেল",
        brand: "ব্র্যান্ড",
        printers: "প্রিন্টারগুলি",
        printerWithDot: "প্রিন্টার: ",
        errorDuringGetPrinters: "প্রিন্টার পুনরুদ্ধারের সময় ত্রুটি",
        filling: 'পূরণ',
        fillingWithDots: 'পূরণ: ',
        productName: 'পণ্যের নাম',
        color: 'রঙ',
        colorWithDots: 'রঙ: ',
        selectAColor: 'একটি রঙ নির্বাচন করুন',
        addColor: 'একটি রঙ যোগ করুন',
        quantity: 'পরিমাণ',
        quantityWithDots: 'পরিমাণ: ',
        chooseAStl: 'একটি STL ফাইল নির্বাচন করুন',
        deleteAProduct: 'পণ্যটি মুছুন',
        coil: 'কয়েল',
        coilWithDots: 'কয়েল: ',
        remainingWeight: 'অবশিষ্ট ওজন:',
        inputProductName: 'পণ্যের নাম লিখুন',
        inputQuantity: 'পরিমাণ লিখুন',
        presumedDefective: 'সম্ভাব্য ত্রুটিপূর্ণ',
        errorSetCoilDefective: 'কয়েলকে ত্রুটিপূর্ণ হিসাবে চিহ্নিত করার সময় ত্রুটি',
        successSetCoilDefective: 'কয়েল সফলভাবে ত্রুটিপূর্ণ হিসাবে চিহ্নিত করা হয়েছে',
        newCoilWeight: 'নতুন কয়েলের ওজন',
        orderBy: 'অর্ডার করেছেন: ',
        todo: 'করার আছে',
        wip: 'চলমান',
        ended: 'শেষ',
        endedAndPaid: 'শেষ এবং পরিশোধিত',
        cancel: 'বাতিল',
        todoPrint: 'প্রিন্ট করতে হবে',
        printing: 'প্রিন্ট হচ্ছে',
        printed: 'প্রিন্টেড',
        printCancel: 'বাতিল প্রিন্ট',
        delivryAddressWithDots: 'ডেলিভারির ঠিকানা: ',
        orderDate: 'অর্ডারের তারিখ: ',
        errorDuringDeleteMessage: 'বার্তা মুছার সময় ত্রুটি',
        successDeleteMessage: 'বার্তাটি সফলভাবে মুছে ফেলা হয়েছে',
        labelCannotBeEmpty: 'লেবেল খালি হতে পারবে না',
        weightWithDots: 'ওজন: ',
        timeWithDots: 'সময়: ',
        chooseACoil: 'একটি কয়েল নির্বাচন করুন',
        downloadStl: 'STL ডাউনলোড করুন',
        errorDuringGetOrder: 'অর্ডার পুনরুদ্ধারের সময় ত্রুটি',
        errorDuringServerConnexion: 'সার্ভারের সাথে সংযোগ করার সময় ত্রুটি',
        productStatusUpdated: 'পণ্যের স্থিতি সফলভাবে আপডেট করা হয়েছে',
        needFillAllFields: 'সব ফিল্ড পূরণ করুন',
        weightNeedBeNumber: 'ওজন একটি সংখ্যা হতে হবে',
        weightAndTimeUpdated: 'অর্ডারের ওজন এবং সময় সফলভাবে আপডেট করা হয়েছে',
        cancelProduct: 'পণ্য বাতিল করুন',
        changeToPrinting: 'স্থিতি পরিবর্তন করে "প্রিন্ট হচ্ছে" করুন',
        version: 'সংস্করণ',
        versionDescription: 'সংস্করণ বর্ণনা',
        errorDuringDeleteVersion: 'সংস্করণ মুছার সময় ত্রুটি',
        versionDeleted: 'সংস্করণ সফলভাবে মুছে ফেলা হয়েছে',
        errorDuringEditVersion: 'সংস্করণ সম্পাদনার সময় ত্রুটি',
        versionEdited: 'সংস্করণ সফলভাবে সম্পাদিত হয়েছে',
        createAColor: 'একটি রঙ তৈরি করুন',
        colorName: 'রঙের নাম',
        validate: 'বৈধতা',
        cancelAction: 'বাতিল করুন',
        colorNameCannotBeEmpty: 'রঙের নাম খালি হতে পারবে না',
        errorDuringCreateColor: 'রঙ তৈরি করার সময় ত্রুটি',
        createSucces: 'সফলভাবে তৈরি করা হয়েছে',
        erreurDuringFillFields: 'ফিল্ড পূরণের সময় ত্রুটি',
        continue: 'চালিয়ে যান',
        confirmDelete: 'মুছে ফেলার নিশ্চিতকরণ?',
        attentionAlertDeleteCoil: 'সতর্কতা! আমাদের স্টকের অনুযায়ী বর্তমানে এই কয়েলে আছে ',
        attentionAlertDeleteCoilEnd: 'গ্রাম। এই ক্রিয়াটি অপরিবর্তনীয়। আপনি কি এটি মুছে ফেলার জন্য নিশ্চিত?',
        deleteAction: 'মুছে ফেলুন',
        attentionAlertDeleteColor: 'সতর্কতা! আপনি রঙ মুছে ফেলতে চলেছেন ',
        attentionAlertDeleteColorEnd: '। এই ক্রিয়াটি অপরিবর্তনীয়। আপনি কি এটি মুছে ফেলার জন্য নিশ্চিত?',
        colors: 'রঙগুলি',
        coils: 'কয়েলগুলি',
        createAnOrder: 'একটি অর্ডার তৈরি করুন',
        myOrders: 'আমার অর্ডারগুলি',
        orders: 'অর্ডারগুলি',
        ordersOnly: 'অর্ডারগুলি',
        register: 'নিবন্ধন করুন',
        messages: 'বার্তাগুলি',
        contact: 'যোগাযোগ',
        logout: 'লগ আউট',
        login: 'লগ ইন',
        versionsOnly: 'সংস্করণগুলি',
        myAccount: 'আমার অ্যাকাউন্ট',
        youAreDisconnected: 'আপনি সংযোগ বিচ্ছিন্ন হয়েছেন',
        noNeedFillFormFull: 'আপনাকে পুরো ফর্ম পূরণ করার প্রয়োজন নেই।',
        ifChangeMailOnlyMailWillChange: 'আপনি যদি আপনার ইমেইল পরিবর্তন করতে চান, শুধু ইমেইল ফিল্ড পূরণ করুন।',
        modifyMyInformation: 'আমার তথ্য পরিবর্তন করুন',
        newMail: 'নতুন ইমেইল',
        newPassword: 'নতুন পাসওয়ার্ড',
        confirmPassword: 'পাসওয়ার্ড নিশ্চিত করুন',
        newDelivreryAddress: 'নতুন ডেলিভারির ঠিকানা',
        editAction: 'সম্পাদনা করুন',
        youNeedToBeConnectedToAccessThisPage: 'এই পৃষ্ঠায় অ্যাক্সেস করতে আপনাকে সংযুক্ত থাকতে হবে',
        youNeedToFillOneField: 'আপনাকে অন্তত একটি ফিল্ড পূরণ করতে হবে',
        passwordNotMatch: 'পাসওয়ার্ডগুলি মেলে না',
        personalInfoEditNeedToReconnect: 'ব্যক্তিগত তথ্য সফলভাবে সম্পাদিত হয়েছে\nদয়া করে পুনরায় লগ ইন করুন',
        weightInGrammes: 'ওজন (গ্রাম)',
        addAction: 'যোগ করুন',
        statsPerColor: 'রঙ অনুযায়ী পরিসংখ্যান',
        bobineList: 'কয়েল তালিকা',
        filter: 'ফিল্টার: ',
        all: 'সব',
        youNeedToBeAdminToAccessThisPage: 'এই পৃষ্ঠায় অ্যাক্সেস করতে আপনাকে প্রশাসক হতে হবে',
        remeningWeightInfZero: 'অবশিষ্ট ওজন 0 এর চেয়ে বেশি হতে হবে',
        youNeedToSelectAColor: 'আপনাকে একটি রঙ নির্বাচন করতে হবে',
        showMore: 'আরও দেখান',
        noMessage: 'কোনো বার্তা নেই',
        inscriptionForm: 'নিবন্ধন ফর্ম',
        email: 'ইমেইল ঠিকানা',
        yourEmail: 'আপনার ইমেইল ঠিকানা',
        password: 'পাসওয়ার্ড',
        yourPassword: 'আপনার পাসওয়ার্ড',
        role: 'ভূমিকা',
        chooseARole: 'একটি ভূমিকা নির্বাচন করুন',
        chooseALanguage: 'একটি ভাষা নির্বাচন করুন',
        delivryAdresse: 'ডেলিভারির ঠিকানা',
        delivry: 'ডেলিভারি',
        registerMe: 'নিবন্ধন করুন',
        errorDuringRegisterUser: 'ব্যবহারকারী নিবন্ধন করার সময় ত্রুটি',
        userRegisterSuccess: 'ব্যবহারকারী সফলভাবে নিবন্ধিত হয়েছে',
        message: 'বার্তা',
        yourMessage: 'আপনার বার্তা',
        youDontHaveOrder: 'আপনার কোন অর্ডার নেই',
        leftToPay: 'অবশিষ্ট পেমেন্ট',
        connection: 'সংযোগ',
        firstname: 'প্রথম নাম',
        youtFirstname: 'আপনার প্রথম নাম',
        lastname: 'শেষ নাম',
        yourLastname: 'আপনার শেষ নাম',
        send: 'পাঠান',
        errorDuringSendMessage: 'বার্তা পাঠানোর সময় ত্রুটি',
        messageSent: 'বার্তা সফলভাবে পাঠানো হয়েছে',
        createOrder: 'একটি অর্ডার তৈরি করুন',
        createOrderFor: 'জন্য অর্ডার তৈরি করুন',
        selectAClient: 'একটি ক্লায়েন্ট নির্বাচন করুন',
        product: 'পণ্য',
        orderName: 'অর্ডারের নাম',
        inputOrderName: 'অর্ডারের নাম লিখুন',
        confirmOrder: 'অর্ডার নিশ্চিত করুন',
        requireMinOneProduct: 'আপনার অন্তত একটি পণ্য থাকতে হবে',
        needFillCommandeName: 'আপনাকে একটি অর্ডারের নাম লিখতে হবে',
        youNeedSelectAClient: 'আপনাকে একটি ক্লায়েন্ট নির্বাচন করতে হবে',
        youNeedToAddMinOneProduct: 'আপনাকে অন্তত একটি পণ্য যোগ করতে হবে',
        youNeedFillAllFieldsOfProduct: 'আপনাকে পণ্যগুলির সমস্ত ফিল্ড পূরণ করতে হবে',
        errorDuringCreateOrder: 'অর্ডার তৈরি করার সময় ত্রুটি',
        errorDuringCreateOrderServerError: 'অর্ডার তৈরি করার সময় ত্রুটি (সার্ভার ত্রুটি)',
        orderCreated: 'অর্ডার সফলভাবে তৈরি করা হয়েছে',
        changeToStatus: 'স্থিতি পরিবর্তন করুন',
        idWithDots: 'আইডি: ',
        startDateWithDots: 'শুরুর তারিখ: ',
        userWithDots: 'ব্যবহারকারী: ',
        delivryWithDots: 'ডেলিভারি: ',
        priceKiloWattPerHourWithDots: 'কিলোওয়াট প্রতি ঘণ্টার খরচ: ',
        priceCoilKiloWithDots: 'প্রতি কেজি কয়েলের খরচ: ',
        priceProduction: 'উৎপাদন খরচ: ',
        totalPrice: 'মোট খরচ: ',
        downloadInvoice: 'চালান ডাউনলোড করুন',
        errorDuringDownloadInvoice: 'চালান ডাউনলোড করার সময় ত্রুটি',
        orderStatusUpdated: 'অর্ডারের স্থিতি সফলভাবে আপডেট করা হয়েছে',
        error404: 'ত্রুটি 404',
        pageNotFound: 'আপনি যে পৃষ্ঠাটি খুঁজছেন তা পাওয়া যায়নি।',
        backToHome: 'প্রথম পৃষ্ঠায় ফিরে যান',
        numberOfOrder: 'অর্ডারের সংখ্যা:',
        orderCancel: 'অর্ডার বাতিল হয়েছে:',
        products: 'পণ্যগুলি',
        numberOfProducts: 'পণ্যের সংখ্যা:',
        avgProducts: 'প্রতি অর্ডারে গড় সংখ্যা:',
        weights: 'ওজন',
        totalWeight: 'মোট ওজন:',
        avgWeight: 'গড় ওজন:',
        time: 'সময়',
        totalTime: 'মোট সময়:',
        avgTime: 'গড় সময়:',
        price: 'খরচ',
        coilUsage: 'কয়েল ব্যবহার:',
        orderFor3Years: 'প্রতি মাসে অর্ডার (3 বছর):',
        productFor3Years: 'প্রতি মাসে পণ্য (3 বছর):',
        january: 'জানুয়ারি',
        february: 'ফেব্রুয়ারি',
        march: 'মার্চ',
        april: 'এপ্রিল',
        may: 'মে',
        june: 'জুন',
        july: 'জুলাই',
        august: 'আগস্ট',
        september: 'সেপ্টেম্বর',
        october: 'অক্টোবর',
        november: 'নভেম্বর',
        december: 'ডিসেম্বর',
        versionManagement: 'সংস্করণ ব্যবস্থাপনা',
        major: 'মেজর',
        minor: 'মাইনর',
        fix: 'ফিক্স',
        addVersion: 'একটি সংস্করণ যোগ করুন',
        needFillDescriptionForThisVersion: 'এই সংস্করণের জন্য একটি বর্ণনা প্রদান করুন',
        errorDuringCreateVersion: 'সংস্করণ তৈরি করার সময় ত্রুটি',
        versionCreated: 'সংস্করণ সফলভাবে তৈরি করা হয়েছে',
        errorDuringGetCoil: 'কয়েলগুলি পুনরুদ্ধার করার সময় ত্রুটি',
        errorDuringDeleteCoil: 'কয়েল মুছার সময় ত্রুটি',
        coilDeleted: 'কয়েল সফলভাবে মুছে ফেলা হয়েছে।',
        errorDuringCreateCoil: 'কয়েল তৈরি করার সময় ত্রুটি',
        coilCreated: 'কয়েল সফলভাবে তৈরি করা হয়েছে',
        errorDuringEditCoil: 'কয়েল সম্পাদনা করার সময় ত্রুটি',
        coilEdited: 'কয়েল সফলভাবে সম্পাদিত হয়েছে।',
        errorDuringGetOrders: 'অর্ডারগুলি পুনরুদ্ধার করার সময় ত্রুটি।',
        pleaseRefreshPage: 'পণ্য আপডেট করতে পৃষ্ঠা (f5) রিফ্রেশ করুন',
        errorDuringGetMessage: 'বার্তা পুনরুদ্ধার করার সময় ত্রুটি',
        errorDuringGetColors: 'রঙগুলি পুনরুদ্ধার করার সময় ত্রুটি',
        errorDuringDeleteColors: 'রঙটি মুছার সময় ত্রুটি',
        colorDeleted: 'রঙ সফলভাবে মুছে ফেলা হয়েছে',
        errorDuringEditColor: 'রঙ সম্পাদনা করার সময় ত্রুটি',
        colorEdited: 'রঙ সফলভাবে সম্পাদিত হয়েছে',
        errorDuringGetRoles: 'ভূমিকা পুনরুদ্ধার করার সময় ত্রুটি',
        errorDuringGetStats: 'পরিসংখ্যান পুনরুদ্ধার করার সময় ত্রুটি',
        stats: 'পরিসংখ্যান',
        versions: 'সংস্করণগুলি',
        orderDetail: 'অর্ডার বিবরণ',
        pageNotFoundShort: 'পৃষ্ঠাটি পাওয়া যায়নি',
        admin: 'প্রশাসক',
        client: 'ক্লায়েন্ট',
        language: 'ভাষা',
        french: 'ফরাসি',
        english: 'ইংরেজি',
        hindi: 'হিন্দি',
        chinese: 'চীনা',
        spanish: 'স্প্যানিশ',
        arabic: 'আরবি',
        bengali: 'বাংলা',
        portuguese: 'পর্তুগিজ',
        russian: 'রাশিয়ান',
        urdu: 'উর্দু',
        newLanguage: 'নতুন ভাষা',
    },
    pt: {
        profit: "Lucro",
        productsByPrinter: "Produtos por impressora",
        productEditedSuccessfully: "Produto editado com sucesso",
        selectCoil: "Selecionar bobinas",
        cancel2: "Cancelar",
        numberObjetAvailable: "Número de objetos restantes",
        allClient: "Todos os clientes",
        allStatus: "Todos os status",
        noOrderCorresponding: "Nenhum pedido corresponde à sua pesquisa",
        selectAState: "Selecione um estado",
        errorDuringGetStates: "Erro ao recuperar os estados",
        selectAFilament: "Selecione um filamento",
        filamentWithDots: "Filamentos: ",
        typeFilamentCreated: "Tipo de filamento criado",
        errorDuringCreateTypeFilament: "Erro durante a criação do tipo de filamento",
        typeFilamentDeleted: "Tipo de filamento excluído",
        errorDuringDeleteFilamentType: "Erro durante a exclusão do tipo de filamento",
        AlertDeleteTypeFilament: "Atenção! Você está prestes a excluir o tipo de filamento. Tem certeza de que deseja excluí-lo?",
        typeFilamentEdited: "Tipo de filamento editado",
        errorDuringEditingTypeFilament: "Erro durante a edição do tipo de filamento",
        libelleCannotBeEmpty: "O rótulo não pode estar vazio",
        noTypeFilament: "Nenhum tipo de filamento",
        errorDuringLoadFilamentType: "Erro ao carregar os tipos de filamento",
        label: "Rótulo",
        newLabelTypeOfFilament: "Novo rótulo do tipo de filamento",
        filamentsType: "Tipos de filamento",
        youNeedToSelectABrand: "Você precisa selecionar uma marca",
        selectABrand: "Selecione uma marca",
        brandWithDots: "Marca: ",
        AlertDeleteBrand: "Aviso! Você está prestes a excluir a marca. Tem certeza de que deseja excluí-la?",
        brandDeleted: "Marca excluída com sucesso",
        errorDuringDeleteMarque: "Erro durante a exclusão da marca",
        noBrand: "Nenhuma marca",
        brandEdited: "Marca editada com sucesso",
        errorDuringEditBrand: "Erro ao editar a marca",
        brandCreated: "Marca criada com sucesso",
        errorDuringCreateBrand: "Erro durante a criação da marca",
        needFillBrandName: "Por favor, insira o nome da marca",
        brandName: "Nome da marca",
        errorDuringLoadBrand: "Erro ao carregar as marcas",
        brands: "Marcas",
        closeAction: "Fechar",
        show3D: "Mostrar em 3D",
        showFailedPrint: "Mostrar impressões falhadas",
        causeWithDots: "Causa: ",
        failedCreated: "\"Falhou\" criado com sucesso",
        errorDuringCreatFailed: "Erro durante a criação de \"falhou\"",
        needFillCause: "Por favor, insira a causa",
        causeOfFailed: "Causa da falha",
        TimeInfZero: "O tempo deve ser superior a 0.",
        weightInfZero: "O peso deve ser superior a 0.",
        needFillCoil: "Por favor, insira uma bobina.",
        needFillPrinter: "Por favor, insira uma impressora.",
        addAFailedObject: "Adicionar um objeto falhado",
        consumption: "Consumo:",
        totalPriceKwhConsumption: "Custo total de consumo (em €):",
        consumptionKwh: "Consumo (em KWh):",
        avgTimeParPiece: "Tempo médio por peça:",
        avgProductPriceParPiece: "Custo médio de produção por peça:",
        statsPrinter: "Estatísticas da impressora",
        sort: "ordenar",
        chooseSort: "Escolher uma ordenação",
        sortByNumber: "Ordenar por número",
        sortByWeight: "Ordenar por peso",
        asc: "Ascendente",
        desc: "Descendente",
        successSetCoilUndefective: "Bobina marcada como não defeituosa",
        errorDuringActiveInformations: "Erro ao recuperar informações ativas",
        errorDuringInformations: "Erro ao recuperar informações",
        informations: "As informações",
        endDate: "Data de término",
        any: "nenhuma",
        attentionAlertDeleteInformation: "Atenção! Você está prestes a excluir a informação. Tem certeza de que deseja excluí-la?",
        errorDuringDeleteInformation: "Erro ao excluir a informação",
        informationDeleted: "Informação excluída com sucesso",
        messageCannotBeEmpty: "A mensagem não pode estar vazia",
        errorDuringEditInformation: "Erro ao editar a informação",
        informationEdited: "Informação editada com sucesso",
        noInformation: "Nenhuma informação",
        informationMessage: "Mensagem da informação",
        errorDuringCreateInformation: "Erro ao criar a informação",
        informationCreate: "Informação criada com sucesso",
        chooseAPrinter: "Escolha uma impressora",
        printerEdited: "Impressora editada com sucesso",
        errorDuringEditPrinter: "Erro durante a edição da impressora",
        printerDeleted: "Impressora excluída com sucesso",
        errorDuringDeletePrinter: "Erro durante a exclusão da impressora",
        attentionAlertDeletePrinter: "Atenção! Você está prestes a excluir a impressora ",
        attentionAlertDeletePrinterEnd: ". Esta ação é irreversível. Você tem certeza de que deseja excluí-la?",
        urlImgPrinter: "URL da imagem da impressora",
        printerCreated: "Impressora criada com sucesso",
        errorDuringCreatePrinter: "Erro durante a criação da impressora",
        consommation: "Consumo (em watts)",
        model: "Modelo",
        brand: "Marca",
        printers: "Impressoras",
        printerWithDot: "Impressora: ",
        errorDuringGetPrinters: "Erro ao recuperar impressoras",
        filling: 'Preenchimento',
        fillingWithDots: 'Preenchimento: ',
        productName: 'Nome do produto',
        color: 'Cor',
        colorWithDots: 'Cor: ',
        selectAColor: 'Selecione uma cor',
        addColor: 'Adicionar uma cor',
        quantity: 'Quantidade',
        quantityWithDots: 'Quantidade: ',
        chooseAStl: 'Escolher um arquivo STL',
        deleteAProduct: 'Excluir o produto',
        coil: 'Bobina',
        coilWithDots: 'Bobina: ',
        remainingWeight: 'Peso Restante:',
        inputProductName: 'Digite o nome do produto',
        inputQuantity: 'Digite a quantidade',
        presumedDefective: 'Presumido defeituoso',
        errorSetCoilDefective: 'Erro ao marcar a bobina como defeituosa',
        successSetCoilDefective: 'Bobina marcada como defeituosa',
        newCoilWeight: 'Novo peso da bobina',
        orderBy: 'Ordenado por: ',
        todo: 'A fazer',
        wip: 'Em progresso',
        ended: 'Finalizado',
        endedAndPaid: 'Finalizado e pago',
        cancel: 'Cancelado',
        todoPrint: 'A imprimir',
        printing: 'Imprimindo',
        printed: 'Impresso',
        printCancel: 'Impressão cancelada',
        delivryAddressWithDots: 'Endereço de entrega: ',
        orderDate: 'Data do pedido: ',
        errorDuringDeleteMessage: 'Erro ao excluir a mensagem',
        successDeleteMessage: 'Mensagem excluída com sucesso',
        labelCannotBeEmpty: 'O rótulo não pode estar vazio',
        weightWithDots: 'Peso: ',
        timeWithDots: 'Tempo: ',
        chooseACoil: 'Escolha uma bobina',
        downloadStl: 'Baixar STL',
        errorDuringGetOrder: 'Erro ao recuperar o pedido',
        errorDuringServerConnexion: 'Erro ao conectar ao servidor',
        productStatusUpdated: 'Status do produto atualizado com sucesso',
        needFillAllFields: 'Por favor, preencha todos os campos',
        weightNeedBeNumber: 'O peso deve ser um número',
        weightAndTimeUpdated: 'Peso e tempo do pedido atualizados com sucesso',
        cancelProduct: 'Cancelar produto',
        changeToPrinting: 'Mudar para o status "Imprimindo"',
        version: 'Versão',
        versionDescription: 'Descrição da versão',
        errorDuringDeleteVersion: 'Erro ao excluir a versão',
        versionDeleted: 'Versão excluída com sucesso',
        errorDuringEditVersion: 'Erro ao editar a versão',
        versionEdited: 'Versão editada com sucesso',
        createAColor: 'Criar uma cor',
        colorName: 'Nome da cor',
        validate: 'Validar',
        cancelAction: 'Cancelar',
        colorNameCannotBeEmpty: 'O nome da cor não pode estar vazio',
        errorDuringCreateColor: 'Erro ao criar a cor',
        createSucces: 'Criado com sucesso',
        erreurDuringFillFields: 'Erro ao preencher os campos',
        continue: 'Continuar',
        confirmDelete: 'Confirmar exclusão?',
        attentionAlertDeleteCoil: 'Atenção! Atualmente restam ',
        attentionAlertDeleteCoilEnd: 'grama(s) nesta bobina de acordo com nosso estoque. Esta ação é irreversível. Tem certeza de que deseja excluí-la?',
        deleteAction: 'Excluir',
        attentionAlertDeleteColor: 'Atenção! Você está prestes a excluir a cor ',
        attentionAlertDeleteColorEnd: '. Esta ação é irreversível. Tem certeza de que deseja excluí-la?',
        colors: 'Cores',
        coils: 'Bobinas',
        createAnOrder: 'Criar um pedido',
        myOrders: 'Meus pedidos',
        orders: 'Pedidos',
        ordersOnly: 'Pedidos',
        register: 'Registrar',
        messages: 'Mensagens',
        contact: 'Contato',
        logout: 'Sair',
        login: 'Entrar',
        versionsOnly: 'Versões',
        myAccount: 'Minha conta',
        youAreDisconnected: 'Você está desconectado',
        noNeedFillFormFull: 'Você não precisa preencher todo o formulário.',
        ifChangeMailOnlyMailWillChange: 'Se você deseja mudar seu e-mail, preencha apenas o campo de e-mail.',
        modifyMyInformation: 'Modificar minhas informações',
        newMail: 'Novo E-mail',
        newPassword: 'Nova Senha',
        confirmPassword: 'Confirmar senha',
        newDelivreryAddress: 'Novo Endereço de entrega',
        editAction: 'Editar',
        youNeedToBeConnectedToAccessThisPage: 'Você precisa estar conectado para acessar esta página',
        youNeedToFillOneField: 'Você precisa preencher pelo menos um campo',
        passwordNotMatch: 'As senhas não correspondem',
        personalInfoEditNeedToReconnect: 'Informações pessoais editadas com sucesso\nPor favor, reconecte-se',
        weightInGrammes: 'peso em gramas',
        addAction: 'Adicionar',
        statsPerColor: 'Estatísticas por Cor',
        bobineList: 'Lista de Bobinas',
        filter: 'Filtro: ',
        all: 'Todos',
        youNeedToBeAdminToAccessThisPage: 'Você precisa ser administrador para acessar esta página',
        remeningWeightInfZero: 'O peso restante deve ser superior a 0',
        youNeedToSelectAColor: 'Você precisa selecionar uma cor',
        showMore: 'Mostrar mais',
        noMessage: 'Sem mensagens',
        inscriptionForm: 'Formulário de Inscrição',
        email: 'Endereço de e-mail',
        yourEmail: 'Seu endereço de e-mail',
        password: 'Senha',
        yourPassword: 'Sua senha',
        role: 'Função',
        chooseARole: 'Escolher uma função',
        chooseALanguage: 'Escolher um idioma',
        delivryAdresse: 'Endereço de entrega',
        delivry: 'Entrega',
        registerMe: 'Inscrever-se',
        errorDuringRegisterUser: 'Erro ao registrar o usuário',
        userRegisterSuccess: 'Usuário registrado com sucesso',
        message: 'Mensagem',
        yourMessage: 'Sua mensagem',
        youDontHaveOrder: 'Você não tem nenhum pedido',
        leftToPay: 'Restante a pagar',
        connection: 'Conexão',
        firstname: 'Nome',
        youtFirstname: 'Seu nome',
        lastname: 'Sobrenome',
        yourLastname: 'Seu sobrenome',
        send: 'Enviar',
        errorDuringSendMessage: 'Erro ao enviar a mensagem',
        messageSent: 'Mensagem enviada com sucesso',
        createOrder: 'Criar um pedido',
        createOrderFor: 'Criar um pedido para',
        selectAClient: 'Selecione um cliente',
        product: 'Produto',
        orderName: 'Nome do pedido',
        inputOrderName: 'Digite o nome do pedido',
        confirmOrder: 'Confirmar pedido',
        requireMinOneProduct: 'Você deve ter pelo menos um produto',
        needFillCommandeName: 'Você deve digitar um nome de pedido',
        youNeedSelectAClient: 'Você precisa selecionar um cliente',
        youNeedToAddMinOneProduct: 'Você precisa adicionar pelo menos um produto',
        youNeedFillAllFieldsOfProduct: 'Você precisa preencher todos os campos dos produtos',
        errorDuringCreateOrder: 'Erro ao criar o pedido',
        errorDuringCreateOrderServerError: 'Erro ao criar o pedido (erro no servidor)',
        orderCreated: 'Pedido criado com sucesso',
        changeToStatus: 'Mudar para o status',
        idWithDots: 'ID: ',
        startDateWithDots: 'Data de início: ',
        userWithDots: 'Usuário: ',
        delivryWithDots: 'Entrega: ',
        priceKiloWattPerHourWithDots: 'Custo KW/H: ',
        priceCoilKiloWithDots: 'Custo por quilo de bobina: ',
        priceProduction: 'Custo de produção: ',
        totalPrice: 'Custo Total: ',
        downloadInvoice: 'Baixar fatura',
        errorDuringDownloadInvoice: 'Erro ao baixar a fatura',
        orderStatusUpdated: 'Status do pedido atualizado com sucesso',
        error404: 'Erro 404',
        pageNotFound: 'A página que você está procurando não foi encontrada.',
        backToHome: 'Voltar à página inicial',
        numberOfOrder: 'Número de pedidos:',
        orderCancel: 'Pedido cancelado:',
        products: 'Produtos',
        numberOfProducts: 'Número de produtos:',
        avgProducts: 'Média por pedido:',
        weights: 'Pesos',
        totalWeight: 'Peso total:',
        avgWeight: 'Peso médio:',
        time: 'Tempo',
        totalTime: 'Tempo total:',
        avgTime: 'Tempo médio:',
        price: 'Custos',
        coilUsage: 'Uso de bobinas:',
        orderFor3Years: 'Pedidos por mês (3 anos):',
        productFor3Years: 'Produtos por mês (3 anos):',
        january: 'Jan',
        february: 'Fev',
        march: 'Mar',
        april: 'Abr',
        may: 'Mai',
        june: 'Jun',
        july: 'Jul',
        august: 'Ago',
        september: 'Set',
        october: 'Out',
        november: 'Nov',
        december: 'Dez',
        versionManagement: 'Gerenciamento de versão',
        major: 'Principal',
        minor: 'Menor',
        fix: 'Correção',
        addVersion: 'Adicionar uma versão',
        needFillDescriptionForThisVersion: 'Por favor, forneça uma descrição para a versão',
        errorDuringCreateVersion: 'Erro ao criar a versão',
        versionCreated: 'Versão criada com sucesso',
        errorDuringGetCoil: 'Erro ao recuperar bobinas',
        errorDuringDeleteCoil: 'Erro ao excluir a bobina',
        coilDeleted: 'Bobina excluída com sucesso.',
        errorDuringCreateCoil: 'Erro ao criar a bobina',
        coilCreated: 'Bobina criada com sucesso',
        errorDuringEditCoil: 'Erro ao editar a bobina',
        coilEdited: 'Bobina editada com sucesso.',
        errorDuringGetOrders: 'Erro ao recuperar pedidos.',
        pleaseRefreshPage: 'Por favor, atualize a página (f5) para atualizar o produto',
        errorDuringGetMessage: 'Erro ao recuperar mensagens',
        errorDuringGetColors: 'Erro ao recuperar cores',
        errorDuringDeleteColors: 'Erro ao excluir a cor',
        colorDeleted: 'Cor excluída com sucesso',
        errorDuringEditColor: 'Erro ao editar a cor',
        colorEdited: 'A cor foi editada com sucesso',
        errorDuringGetRoles: 'Erro ao recuperar funções',
        errorDuringGetStats: 'Erro ao recuperar estatísticas',
        stats: 'Estatísticas',
        versions: 'Versões',
        orderDetail: 'Detalhe do pedido',
        pageNotFoundShort: 'Página não encontrada',
        admin: 'Administrador',
        client: 'Cliente',
        language: 'Idioma',
        french: 'Francês',
        english: 'Inglês',
        hindi: 'Hindi',
        chinese: 'Chinês',
        spanish: 'Espanhol',
        arabic: 'Árabe',
        bengali: 'Bengali',
        portuguese: 'Português',
        russian: 'Russo',
        urdu: 'Urdu',
        newLanguage: 'Novo idioma',
    },
    ru: {
        profit: "Прибыль",
        productsByPrinter: "Продукты по принтеру",
        productEditedSuccessfully: "Продукт успешно отредактирован",
        selectCoil: "Выбрать катушки",
        cancel2: "Отмена",
        numberObjetAvailable: "Количество оставшихся объектов",
        allClient: "Все клиенты",
        allStatus: "Все статусы",
        noOrderCorresponding: "Заказов, соответствующих вашему запросу, не найдено",
        selectAState: "Выберите штат",
        errorDuringGetStates: "Ошибка при получении состояний",
        selectAFilament: "Выберите филамент",
        filamentWithDots: "Филаменты: ",
        typeFilamentCreated: "Тип филамента создан",
        errorDuringCreateTypeFilament: "Ошибка при создании типа филамента",
        typeFilamentDeleted: "Тип филамента удален",
        errorDuringDeleteFilamentType: "Ошибка при удалении типа филамента",
        AlertDeleteTypeFilament: "Внимание! Вы собираетесь удалить тип филамента. Вы уверены, что хотите его удалить?",
        typeFilamentEdited: "Тип филамента изменен",
        errorDuringEditingTypeFilament: "Ошибка при редактировании типа филамента",
        libelleCannotBeEmpty: "Метка не может быть пустой",
        noTypeFilament: "Нет типа филамента",
        errorDuringLoadFilamentType: "Ошибка при загрузке типов филамента",
        label: "Метка",
        newLabelTypeOfFilament: "Новая метка типа филамента",
        filamentsType: "Типы филаментов",
        youNeedToSelectABrand: "Вам нужно выбрать бренд",
        selectABrand: "Выберите бренд",
        brandWithDots: "Бренд: ",
        AlertDeleteBrand: "Внимание! Вы собираетесь удалить бренд. Вы уверены, что хотите удалить его?",
        brandDeleted: "Бренд успешно удален",
        errorDuringDeleteMarque: "Ошибка при удалении бренда",
        noBrand: "Нет бренда",
        brandEdited: "Бренд успешно отредактирован",
        errorDuringEditBrand: "Ошибка при редактировании бренда",
        brandCreated: "Бренд успешно создан",
        errorDuringCreateBrand: "Ошибка при создании бренда",
        needFillBrandName: "Пожалуйста, введите название бренда",
        brandName: "Название бренда",
        errorDuringLoadBrand: "Ошибка при загрузке брендов",
        brands: "Бренды",
        closeAction: "Закрыть",
        show3D: "Показать в 3D",
        showFailedPrint: "Показать неудавшиеся отпечатки",
        causeWithDots: "Причина: ",
        failedCreated: "\"Неудача\" успешно создана",
        errorDuringCreatFailed: "Ошибка при создании \"неудачи\"",
        needFillCause: "Пожалуйста, введите причину",
        causeOfFailed: "Причина неудачи",
        TimeInfZero: "Время должно быть больше 0.",
        weightInfZero: "Вес должен быть больше 0.",
        needFillCoil: "Пожалуйста, введите катушку.",
        needFillPrinter: "Пожалуйста, введите принтер.",
        addAFailedObject: "Добавить неудачный объект",
        consumption: "Потребление:",
        totalPriceKwhConsumption: "Общая стоимость потребления (в €):",
        consumptionKwh: "Потребление (в кВт·ч):",
        avgTimeParPiece: "Среднее время на деталь:",
        avgProductPriceParPiece: "Средняя стоимость производства на деталь:",
        statsPrinter: "Статистика принтера",
        sort: "сортировка",
        chooseSort: "Выбрать сортировку",
        sortByNumber: "Сортировать по номеру",
        sortByWeight: "Сортировать по весу",
        asc: "По возрастанию",
        desc: "По убыванию",
        successSetCoilUndefective: "Катушка помечена как неисправная",
        errorDuringActiveInformations: "Ошибка при получении активной информации",
        errorDuringInformations: "Ошибка при получении информации",
        informations: "Информация",
        endDate: "Дата окончания",
        any: "никакая",
        attentionAlertDeleteInformation: "Внимание! Вы собираетесь удалить информацию. Вы уверены, что хотите ее удалить?",
        errorDuringDeleteInformation: "Ошибка при удалении информации",
        informationDeleted: "Информация успешно удалена",
        messageCannotBeEmpty: "Сообщение не может быть пустым",
        errorDuringEditInformation: "Ошибка при редактировании информации",
        informationEdited: "Информация успешно отредактирована",
        noInformation: "Нет информации",
        informationMessage: "Сообщение информации",
        errorDuringCreateInformation: "Ошибка при создании информации",
        informationCreate: "Информация успешно создана",
        chooseAPrinter: "Выберите принтер",
        printerEdited: "Принтер успешно отредактирован",
        errorDuringEditPrinter: "Ошибка при редактировании принтера",
        printerDeleted: "Принтер успешно удалён",
        errorDuringDeletePrinter: "Ошибка при удалении принтера",
        attentionAlertDeletePrinter: "Внимание! Вы собираетесь удалить принтер ",
        attentionAlertDeletePrinterEnd: ". Это действие необратимо. Вы уверены, что хотите его удалить?",
        urlImgPrinter: "URL изображения принтера",
        printerCreated: "Принтер успешно создан",
        errorDuringCreatePrinter: "Ошибка при создании принтера",
        consommation: "Потребление (в ваттах)",
        model: "Модель",
        brand: "Бренд",
        printers: "Принтеры",
        printerWithDot: "Принтер: ",
        errorDuringGetPrinters: "Ошибка при получении принтеров",
        filling: 'Заполнение',
        fillingWithDots: 'Заполнение: ',
        productName: 'Название продукта',
        color: 'Цвет',
        colorWithDots: 'Цвет: ',
        selectAColor: 'Выберите цвет',
        addColor: 'Добавить цвет',
        quantity: 'Количество',
        quantityWithDots: 'Количество: ',
        chooseAStl: 'Выбрать файл STL',
        deleteAProduct: 'Удалить продукт',
        coil: 'Катушка',
        coilWithDots: 'Катушка: ',
        remainingWeight: 'Оставшийся вес:',
        inputProductName: 'Введите название продукта',
        inputQuantity: 'Введите количество',
        presumedDefective: 'Предполагается дефектный',
        errorSetCoilDefective: 'Ошибка при пометке катушки как дефектной',
        successSetCoilDefective: 'Катушка успешно помечена как дефектная',
        newCoilWeight: 'Новый вес катушки',
        orderBy: 'Заказано: ',
        todo: 'Сделать',
        wip: 'В процессе',
        ended: 'Завершено',
        endedAndPaid: 'Завершено и оплачено',
        cancel: 'Отменено',
        todoPrint: 'Для печати',
        printing: 'Печать',
        printed: 'Напечатано',
        printCancel: 'Печать отменена',
        delivryAddressWithDots: 'Адрес доставки: ',
        orderDate: 'Дата заказа: ',
        errorDuringDeleteMessage: 'Ошибка при удалении сообщения',
        successDeleteMessage: 'Сообщение успешно удалено',
        labelCannotBeEmpty: 'Метка не может быть пустой',
        weightWithDots: 'Вес: ',
        timeWithDots: 'Время: ',
        chooseACoil: 'Выберите катушку',
        downloadStl: 'Скачать STL',
        errorDuringGetOrder: 'Ошибка при получении заказа',
        errorDuringServerConnexion: 'Ошибка подключения к серверу',
        productStatusUpdated: 'Статус продукта успешно обновлен',
        needFillAllFields: 'Пожалуйста, заполните все поля',
        weightNeedBeNumber: 'Вес должен быть числом',
        weightAndTimeUpdated: 'Вес и время заказа успешно обновлены',
        cancelProduct: 'Отменить продукт',
        changeToPrinting: 'Изменить статус на "Печать"',
        version: 'Версия',
        versionDescription: 'Описание версии',
        errorDuringDeleteVersion: 'Ошибка при удалении версии',
        versionDeleted: 'Версия успешно удалена',
        errorDuringEditVersion: 'Ошибка при редактировании версии',
        versionEdited: 'Версия успешно отредактирована',
        createAColor: 'Создать цвет',
        colorName: 'Название цвета',
        validate: 'Подтвердить',
        cancelAction: 'Отменить',
        colorNameCannotBeEmpty: 'Название цвета не может быть пустым',
        errorDuringCreateColor: 'Ошибка при создании цвета',
        createSucces: 'Успешно создано',
        erreurDuringFillFields: 'Ошибка при заполнении полей',
        continue: 'Продолжить',
        confirmDelete: 'Подтвердить удаление?',
        attentionAlertDeleteCoil: 'Внимание! В настоящее время на этой катушке осталось ',
        attentionAlertDeleteCoilEnd: 'грамм по нашим данным. Это действие необратимо. Вы уверены, что хотите удалить?',
        deleteAction: 'Удалить',
        attentionAlertDeleteColor: 'Внимание! Вы собираетесь удалить цвет ',
        attentionAlertDeleteColorEnd: '. Это действие необратимо. Вы уверены, что хотите удалить?',
        colors: 'Цвета',
        coils: 'Катушки',
        createAnOrder: 'Создать заказ',
        myOrders: 'Мои заказы',
        orders: 'Заказы',
        ordersOnly: 'Заказы',
        register: 'Регистрация',
        messages: 'Сообщения',
        contact: 'Контакт',
        logout: 'Выйти',
        login: 'Войти',
        versionsOnly: 'Версии',
        myAccount: 'Мой аккаунт',
        youAreDisconnected: 'Вы отключены',
        noNeedFillFormFull: 'Вам не нужно заполнять всю форму.',
        ifChangeMailOnlyMailWillChange: 'Если вы хотите изменить электронную почту, заполните только поле электронной почты.',
        modifyMyInformation: 'Изменить мою информацию',
        newMail: 'Новая электронная почта',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтвердить пароль',
        newDelivreryAddress: 'Новый адрес доставки',
        editAction: 'Редактировать',
        youNeedToBeConnectedToAccessThisPage: 'Вы должны быть подключены для доступа к этой странице',
        youNeedToFillOneField: 'Вы должны заполнить хотя бы одно поле',
        passwordNotMatch: 'Пароли не совпадают',
        personalInfoEditNeedToReconnect: 'Персональная информация успешно изменена\nПожалуйста, войдите снова',
        weightInGrammes: 'вес в граммах',
        addAction: 'Добавить',
        statsPerColor: 'Статистика по цвету',
        bobineList: 'Список катушек',
        filter: 'Фильтр: ',
        all: 'Все',
        youNeedToBeAdminToAccessThisPage: 'Вы должны быть администратором для доступа к этой странице',
        remeningWeightInfZero: 'Оставшийся вес должен быть больше 0',
        youNeedToSelectAColor: 'Вы должны выбрать цвет',
        showMore: 'Показать больше',
        noMessage: 'Нет сообщений',
        inscriptionForm: 'Форма регистрации',
        email: 'Электронная почта',
        yourEmail: 'Ваша электронная почта',
        password: 'Пароль',
        yourPassword: 'Ваш пароль',
        role: 'Роль',
        chooseARole: 'Выберите роль',
        chooseALanguage: 'Выберите язык',
        delivryAdresse: 'Адрес доставки',
        delivry: 'Доставка',
        registerMe: 'Зарегистрироваться',
        errorDuringRegisterUser: 'Ошибка при регистрации пользователя',
        userRegisterSuccess: 'Пользователь успешно зарегистрирован',
        message: 'Сообщение',
        yourMessage: 'Ваше сообщение',
        youDontHaveOrder: 'У вас нет заказов',
        leftToPay: 'Осталось оплатить',
        connection: 'Подключение',
        firstname: 'Имя',
        youtFirstname: 'Ваше имя',
        lastname: 'Фамилия',
        yourLastname: 'Ваша фамилия',
        send: 'Отправить',
        errorDuringSendMessage: 'Ошибка при отправке сообщения',
        messageSent: 'Сообщение успешно отправлено',
        createOrder: 'Создание заказа',
        createOrderFor: 'Создание заказа для',
        selectAClient: 'Выберите клиента',
        product: 'Продукт',
        orderName: 'Название заказа',
        inputOrderName: 'Введите название заказа',
        confirmOrder: 'Подтвердить заказ',
        requireMinOneProduct: 'У вас должен быть как минимум один продукт',
        needFillCommandeName: 'Вы должны ввести название заказа',
        youNeedSelectAClient: 'Вы должны выбрать клиента',
        youNeedToAddMinOneProduct: 'Вы должны добавить как минимум один продукт',
        youNeedFillAllFieldsOfProduct: 'Вы должны заполнить все поля продуктов',
        errorDuringCreateOrder: 'Ошибка при создании заказа',
        errorDuringCreateOrderServerError: 'Ошибка при создании заказа (ошибка сервера)',
        orderCreated: 'Заказ успешно создан',
        changeToStatus: 'Изменить статус на',
        idWithDots: 'ID: ',
        startDateWithDots: 'Дата начала: ',
        userWithDots: 'Пользователь: ',
        delivryWithDots: 'Доставка: ',
        priceKiloWattPerHourWithDots: 'Стоимость кВт/ч: ',
        priceCoilKiloWithDots: 'Стоимость катушки за килограмм: ',
        priceProduction: 'Стоимость производства: ',
        totalPrice: 'Общая стоимость: ',
        downloadInvoice: 'Скачать счет',
        errorDuringDownloadInvoice: 'Ошибка при загрузке счета',
        orderStatusUpdated: 'Статус заказа успешно обновлен',
        error404: 'Ошибка 404',
        pageNotFound: 'Страница, которую вы ищете, не найдена.',
        backToHome: 'Вернуться на главную страницу',
        numberOfOrder: 'Количество заказов:',
        orderCancel: 'Заказ отменен:',
        products: 'Продукты',
        numberOfProducts: 'Количество продуктов:',
        avgProducts: 'Среднее количество на заказ:',
        weights: 'Весы',
        totalWeight: 'Общий вес:',
        avgWeight: 'Средний вес:',
        time: 'Время',
        totalTime: 'Общее время:',
        avgTime: 'Среднее время:',
        price: 'Стоимость',
        coilUsage: 'Использование катушек:',
        orderFor3Years: 'Заказы по месяцам (3 года):',
        productFor3Years: 'Продукты по месяцам (3 года):',
        january: 'Янв',
        february: 'Фев',
        march: 'Мар',
        april: 'Апр',
        may: 'Май',
        june: 'Июн',
        july: 'Июл',
        august: 'Авг',
        september: 'Сен',
        october: 'Окт',
        november: 'Ноя',
        december: 'Дек',
        versionManagement: 'Управление версиями',
        major: 'Основной',
        minor: 'Минорный',
        fix: 'Исправление',
        addVersion: 'Добавить версию',
        needFillDescriptionForThisVersion: 'Пожалуйста, предоставьте описание для версии',
        errorDuringCreateVersion: 'Ошибка при создании версии',
        versionCreated: 'Версия успешно создана',
        errorDuringGetCoil: 'Ошибка при получении катушек',
        errorDuringDeleteCoil: 'Ошибка при удалении катушки',
        coilDeleted: 'Катушка успешно удалена.',
        errorDuringCreateCoil: 'Ошибка при создании катушки',
        coilCreated: 'Катушка успешно создана',
        errorDuringEditCoil: 'Ошибка при редактировании катушки',
        coilEdited: 'Катушка успешно отредактирована.',
        errorDuringGetOrders: 'Ошибка при получении заказов.',
        pleaseRefreshPage: 'Пожалуйста, обновите страницу (f5), чтобы обновить продукт',
        errorDuringGetMessage: 'Ошибка при получении сообщений',
        errorDuringGetColors: 'Ошибка при получении цветов',
        errorDuringDeleteColors: 'Ошибка при удалении цвета',
        colorDeleted: 'Цвет успешно удален',
        errorDuringEditColor: 'Ошибка при редактировании цвета',
        colorEdited: 'Цвет успешно отредактирован',
        errorDuringGetRoles: 'Ошибка при получении ролей',
        errorDuringGetStats: 'Ошибка при получении статистики',
        stats: 'Статистика',
        versions: 'Версии',
        orderDetail: 'Детали заказа',
        pageNotFoundShort: 'Страница не найдена',
        admin: 'Администратор',
        client: 'Клиент',
        language: 'Язык',
        french: 'Французский',
        english: 'Английский',
        hindi: 'Хинди',
        chinese: 'Китайский',
        spanish: 'Испанский',
        arabic: 'Арабский',
        bengali: 'Бенгальский',
        portuguese: 'Португальский',
        russian: 'Русский',
        urdu: 'Урду',
        newLanguage: 'Новый язык',
    },
    ur: {
        profit: "منافع",
        productsByPrinter: "پرنٹر کے لحاظ سے مصنوعات",
        productEditedSuccessfully: "پروڈکٹ کامیابی کے ساتھ ترمیم ہو گئی",
        selectCoil: "کنڈلیوں کا انتخاب کریں",
        cancel2: "منسوخ کریں",
        numberObjetAvailable: "بقیہ اشیاء کی تعداد",
        allClient: "تمام گاہک",
        allStatus: "تمام حالتیں",
        noOrderCorresponding: "آپ کی تلاش کے مطابق کوئی آرڈر نہیں ملا",
        selectAState: "ایک ریاست منتخب کریں",
        errorDuringGetStates: "ریاستوں کو حاصل کرنے میں خرابی",
        selectAFilament: "ایک فلامنٹ منتخب کریں",
        filamentWithDots: "فلامنٹس: ",
        typeFilamentCreated: "فلامنٹ کی قسم بنائی گئی",
        errorDuringCreateTypeFilament: "فلامنٹ کی قسم بنانے کے دوران خرابی",
        typeFilamentDeleted: "فلامنٹ کی قسم حذف ہو گئی",
        errorDuringDeleteFilamentType: "فلامنٹ کی قسم حذف کرتے وقت خرابی",
        AlertDeleteTypeFilament: "انتباہ! آپ فلامنٹ کی قسم حذف کرنے والے ہیں۔ کیا آپ اسے حذف کرنا یقینی ہیں؟",
        typeFilamentEdited: "فلامنٹ کی قسم میں ترمیم کی گئی ہے",
        errorDuringEditingTypeFilament: "فلامنٹ کی قسم میں ترمیم کرتے وقت خرابی",
        libelleCannotBeEmpty: "لیبل خالی نہیں ہو سکتا",
        noTypeFilament: "کوئی فلامنٹ کی قسم نہیں",
        errorDuringLoadFilamentType: "فلامنٹ کی اقسام کو لوڈ کرتے وقت خرابی",
        label: "لیبل",
        newLabelTypeOfFilament: "فلامنٹ کی قسم کا نیا لیبل",
        filamentsType: "فلامنٹ کی اقسام",
        youNeedToSelectABrand: "آپ کو ایک برانڈ منتخب کرنے کی ضرورت ہے",
        selectABrand: "ایک برانڈ منتخب کریں",
        brandWithDots: "برانڈ: ",
        AlertDeleteBrand: "انتباہ! آپ برانڈ کو حذف کرنے والے ہیں۔ کیا آپ اس کو حذف کرنا یقینی ہیں؟",
        brandDeleted: "برانڈ کامیابی سے حذف ہوگیا",
        errorDuringDeleteMarque: "برانڈ کو حذف کرتے وقت خرابی",
        noBrand: "کوئی برانڈ نہیں",
        brandEdited: "برانڈ کامیابی سے ترمیم ہوگیا",
        errorDuringEditBrand: "برانڈ میں ترمیم کرتے وقت خرابی",
        brandCreated: "برانڈ کامیابی سے تخلیق ہوگیا",
        errorDuringCreateBrand: "برانڈ تخلیق کرتے وقت خرابی",
        needFillBrandName: "براہ کرم برانڈ کا نام درج کریں",
        brandName: "برانڈ کا نام",
        errorDuringLoadBrand: "برانڈز کو لوڈ کرتے وقت خرابی",
        brands: "برانڈز",
        closeAction: "بند کریں",
        show3D: "3D میں دکھائیں",
        showFailedPrint: "ناکام پرنٹس دکھائیں",
        causeWithDots: "سبب: ",
        failedCreated: "\"ناکام\" کامیابی سے تخلیق کیا گیا",
        errorDuringCreatFailed: "\"ناکام\" بنانے کے دوران خرابی",
        needFillCause: "براہ کرم وجہ درج کریں",
        causeOfFailed: "ناکامی کی وجہ",
        TimeInfZero: "وقت 0 سے زیادہ ہونا چاہئے۔",
        weightInfZero: "وزن 0 سے زیادہ ہونا چاہئے۔",
        needFillCoil: "براہ کرم ایک کنڈلی درج کریں۔",
        needFillPrinter: "براہ کرم ایک پرنٹر درج کریں۔",
        addAFailedObject: "ناکام چیز شامل کریں",
        consumption: "کھپت:",
        totalPriceKwhConsumption: "کھپت کی کل لاگت (یورو میں):",
        consumptionKwh: "کھپت (کلو واٹ میں):",
        avgTimeParPiece: "فی ٹکڑا اوسط وقت:",
        avgProductPriceParPiece: "فی ٹکڑا اوسط پیداواری لاگت:",
        statsPrinter: "پرنٹر کے اعدادوشمار",
        sort: "ترتیب دیں",
        chooseSort: "ترتیب کا انتخاب کریں",
        sortByNumber: "نمبر کے لحاظ سے ترتیب دیں",
        sortByWeight: "وزن کے لحاظ سے ترتیب دیں",
        asc: "صعودی",
        desc: "نزولی",
        successSetCoilUndefective: "کنڈلی کو غیر معیوب کے طور پر نشان زد کیا گیا ہے",
        errorDuringActiveInformations: "فعال معلومات بازیافت کرنے میں خرابی",
        errorDuringInformations: "معلومات بازیافت کرنے میں خرابی",
        informations: "معلومات",
        endDate: "اختتامی تاریخ",
        any: "کوئی نہیں",
        attentionAlertDeleteInformation: "انتباہ! آپ معلومات کو حذف کرنے والے ہیں۔ کیا آپ واقعی اسے حذف کرنا چاہتے ہیں؟",
        errorDuringDeleteInformation: "معلومات کو حذف کرنے میں خرابی",
        informationDeleted: "معلومات کامیابی کے ساتھ حذف ہوگئیں",
        messageCannotBeEmpty: "پیغام خالی نہیں ہو سکتا",
        errorDuringEditInformation: "معلومات میں ترمیم کرنے میں خرابی",
        informationEdited: "معلومات کامیابی سے ترمیم ہو گئیں",
        noInformation: "کوئی معلومات نہیں",
        informationMessage: "معلومات کا پیغام",
        errorDuringCreateInformation: "معلومات بنانے میں خرابی",
        informationCreate: "معلومات کامیابی کے ساتھ بن گئیں",
        chooseAPrinter: "پرنٹر منتخب کریں",
        printerEdited: "پرنٹر کامیابی سے ترمیم کیا گیا",
        errorDuringEditPrinter: "پرنٹر میں ترمیم کے دوران خرابی",
        printerDeleted: "پرنٹر کامیابی سے حذف ہو گیا",
        errorDuringDeletePrinter: "پرنٹر حذف کرنے کے دوران خرابی",
        attentionAlertDeletePrinter: "توجہ! آپ پرنٹر کو حذف کرنے والے ہیں ",
        attentionAlertDeletePrinterEnd: ". یہ عمل ناقابل واپسی ہے۔ کیا آپ واقعی اسے حذف کرنا چاہتے ہیں؟",
        urlImgPrinter: "پرنٹر کی تصویر کا URL",
        printerCreated: "پرنٹر کامیابی سے تخلیق ہو گیا",
        errorDuringCreatePrinter: "پرنٹر تخلیق کرتے وقت خرابی",
        consommation: "کھپت (واٹ میں)",
        model: "ماڈل",
        brand: "برانڈ",
        printers: "پرنٹرز",
        printerWithDot: "پرنٹر: ",
        errorDuringGetPrinters: "پرنٹرز حاصل کرتے وقت خرابی",
        filling: 'بھرائی',
        fillingWithDots: 'بھرائی: ',
        productName: 'پروڈکٹ کا نام',
        color: 'رنگ',
        colorWithDots: 'رنگ: ',
        selectAColor: 'ایک رنگ منتخب کریں',
        addColor: 'ایک رنگ شامل کریں',
        quantity: 'مقدار',
        quantityWithDots: 'مقدار: ',
        chooseAStl: 'ایک STL فائل منتخب کریں',
        deleteAProduct: 'پروڈکٹ حذف کریں',
        coil: 'کوائل',
        coilWithDots: 'کوائل: ',
        remainingWeight: 'باقی وزن:',
        inputProductName: 'پروڈکٹ کا نام درج کریں',
        inputQuantity: 'مقدار درج کریں',
        presumedDefective: 'مفروضہ ناقص',
        errorSetCoilDefective: 'کوائل کو ناقص کے طور پر نشان زد کرنے میں خرابی',
        successSetCoilDefective: 'کوائل کو کامیابی سے ناقص کے طور پر نشان زد کیا گیا',
        newCoilWeight: 'نیا کوائل وزن',
        orderBy: 'آرڈر کردہ: ',
        todo: 'کرنا ہے',
        wip: 'کام جاری ہے',
        ended: 'ختم',
        endedAndPaid: 'ختم اور ادا کیا گیا',
        cancel: 'منسوخ',
        todoPrint: 'پرنٹ کرنے کے لئے',
        printing: 'پرنٹنگ',
        printed: 'پرنٹ کیا گیا',
        printCancel: 'پرنٹ منسوخ',
        delivryAddressWithDots: 'ترسیل کا پتہ: ',
        orderDate: 'آرڈر کی تاریخ: ',
        errorDuringDeleteMessage: 'پیغام حذف کرتے وقت خرابی',
        successDeleteMessage: 'پیغام کامیابی سے حذف کیا گیا',
        labelCannotBeEmpty: 'لیبل خالی نہیں ہوسکتا',
        weightWithDots: 'وزن: ',
        timeWithDots: 'وقت: ',
        chooseACoil: 'ایک کوائل منتخب کریں',
        downloadStl: 'STL ڈاؤن لوڈ کریں',
        errorDuringGetOrder: 'آرڈر حاصل کرنے میں خرابی',
        errorDuringServerConnexion: 'سرور سے کنکشن میں خرابی',
        productStatusUpdated: 'پروڈکٹ کی حیثیت کامیابی سے اپ ڈیٹ کی گئی',
        needFillAllFields: 'براہ کرم تمام فیلڈز کو پُر کریں',
        weightNeedBeNumber: 'وزن ایک نمبر ہونا چاہئے',
        weightAndTimeUpdated: 'آرڈر کا وزن اور وقت کامیابی سے اپ ڈیٹ کیا گیا',
        cancelProduct: 'پروڈکٹ منسوخ کریں',
        changeToPrinting: 'حیثیت کو "پرنٹنگ" میں تبدیل کریں',
        version: 'ورژن',
        versionDescription: 'ورژن کی تفصیل',
        errorDuringDeleteVersion: 'ورژن حذف کرتے وقت خرابی',
        versionDeleted: 'ورژن کامیابی سے حذف کر دیا گیا',
        errorDuringEditVersion: 'ورژن میں ترمیم کرتے وقت خرابی',
        versionEdited: 'ورژن کامیابی سے ترمیم کر دیا گیا',
        createAColor: 'ایک رنگ بنائیں',
        colorName: 'رنگ کا نام',
        validate: 'تصدیق کریں',
        cancelAction: 'منسوخ کریں',
        colorNameCannotBeEmpty: 'رنگ کا نام خالی نہیں ہوسکتا',
        errorDuringCreateColor: 'رنگ بنانے میں خرابی',
        createSucces: 'کامیابی سے بنایا گیا',
        erreurDuringFillFields: 'فیلڈز پُر کرتے وقت خرابی',
        continue: 'جاری رکھیں',
        confirmDelete: 'حذف کی تصدیق کریں؟',
        attentionAlertDeleteCoil: 'توجہ دیں! ہمارے اسٹاک کے مطابق اس وقت اس کوائل پر موجود ہے ',
        attentionAlertDeleteCoilEnd: 'گرام۔ یہ عمل ناقابل واپسی ہے۔ کیا آپ واقعی اسے حذف کرنا چاہتے ہیں؟',
        deleteAction: 'حذف کریں',
        attentionAlertDeleteColor: 'توجہ دیں! آپ رنگ کو حذف کرنے والے ہیں ',
        attentionAlertDeleteColorEnd: '۔ یہ عمل ناقابل واپسی ہے۔ کیا آپ واقعی اسے حذف کرنا چاہتے ہیں؟',
        colors: 'رنگ',
        coils: 'کوائلز',
        createAnOrder: 'ایک آرڈر بنائیں',
        myOrders: 'میرے آرڈرز',
        orders: 'آرڈرز',
        ordersOnly: 'آرڈرز',
        register: 'رجسٹر',
        messages: 'پیغامات',
        contact: 'رابطہ کریں',
        logout: 'لاگ آوٹ',
        login: 'لاگ ان',
        versionsOnly: 'ورژن',
        myAccount: 'میرا اکاؤنٹ',
        youAreDisconnected: 'آپ منقطع ہوگئے ہیں',
        noNeedFillFormFull: 'آپ کو پورا فارم پُر کرنے کی ضرورت نہیں ہے۔',
        ifChangeMailOnlyMailWillChange: 'اگر آپ اپنا ای میل تبدیل کرنا چاہتے ہیں، تو صرف ای میل فیلڈ پُر کریں۔',
        modifyMyInformation: 'میری معلومات میں ترمیم کریں',
        newMail: 'نیا ای میل',
        newPassword: 'نیا پاس ورڈ',
        confirmPassword: 'پاس ورڈ کی تصدیق کریں',
        newDelivreryAddress: 'نیا ترسیل کا پتہ',
        editAction: 'ترمیم کریں',
        youNeedToBeConnectedToAccessThisPage: 'اس صفحہ تک رسائی حاصل کرنے کے لئے آپ کو متصل ہونا چاہئے',
        youNeedToFillOneField: 'آپ کو کم از کم ایک فیلڈ پُر کرنا چاہئے',
        passwordNotMatch: 'پاس ورڈز میل نہیں کھاتے',
        personalInfoEditNeedToReconnect: 'ذاتی معلومات کامیابی کے ساتھ ترمیم کی گئی\nبراہ کرم دوبارہ لاگ ان کریں',
        weightInGrammes: 'وزن (گرام)',
        addAction: 'شامل کریں',
        statsPerColor: 'رنگ کے مطابق اعدادوشمار',
        bobineList: 'کوائل کی فہرست',
        filter: 'فلٹر: ',
        all: 'سب',
        youNeedToBeAdminToAccessThisPage: 'اس صفحہ تک رسائی حاصل کرنے کے لئے آپ کو منتظم ہونا چاہئے',
        remeningWeightInfZero: 'باقی وزن 0 سے زیادہ ہونا چاہئے',
        youNeedToSelectAColor: 'آپ کو ایک رنگ منتخب کرنا چاہئے',
        showMore: 'مزید دکھائیں',
        noMessage: 'کوئی پیغام نہیں',
        inscriptionForm: 'رجسٹریشن فارم',
        email: 'ای میل پتہ',
        yourEmail: 'آپ کا ای میل پتہ',
        password: 'پاس ورڈ',
        yourPassword: 'آپ کا پاس ورڈ',
        role: 'کردار',
        chooseARole: 'ایک کردار منتخب کریں',
        chooseALanguage: 'ایک زبان منتخب کریں',
        delivryAdresse: 'ترسیل کا پتہ',
        delivry: 'ترسیل',
        registerMe: 'رجسٹر کریں',
        errorDuringRegisterUser: 'صارف کو رجسٹر کرتے وقت خرابی',
        userRegisterSuccess: 'صارف کامیابی کے ساتھ رجسٹر ہو گیا',
        message: 'پیغام',
        yourMessage: 'آپ کا پیغام',
        youDontHaveOrder: 'آپ کے پاس کوئی آرڈر نہیں ہے',
        leftToPay: 'ادائیگی کے لئے باقی',
        connection: 'کنکشن',
        firstname: 'پہلا نام',
        youtFirstname: 'آپ کا پہلا نام',
        lastname: 'آخری نام',
        yourLastname: 'آپ کا آخری نام',
        send: 'بھیجیں',
        errorDuringSendMessage: 'پیغام بھیجتے وقت خرابی',
        messageSent: 'پیغام کامیابی کے ساتھ بھیجا گیا',
        createOrder: 'ایک آرڈر بنائیں',
        createOrderFor: 'کے لئے آرڈر بنائیں',
        selectAClient: 'ایک کلائنٹ منتخب کریں',
        product: 'پروڈکٹ',
        orderName: 'آرڈر کا نام',
        inputOrderName: 'آرڈر کا نام درج کریں',
        confirmOrder: 'آرڈر کی تصدیق کریں',
        requireMinOneProduct: 'آپ کے پاس کم از کم ایک پروڈکٹ ہونا چاہئے',
        needFillCommandeName: 'آپ کو آرڈر کا نام درج کرنا ہوگا',
        youNeedSelectAClient: 'آپ کو ایک کلائنٹ منتخب کرنا ہوگا',
        youNeedToAddMinOneProduct: 'آپ کو کم از کم ایک پروڈکٹ شامل کرنا ہوگا',
        youNeedFillAllFieldsOfProduct: 'آپ کو پروڈکٹس کے تمام فیلڈز کو پُر کرنا ہوگا',
        errorDuringCreateOrder: 'آرڈر بنانے میں خرابی',
        errorDuringCreateOrderServerError: 'آرڈر بنانے میں خرابی (سرور کی خرابی)',
        orderCreated: 'آرڈر کامیابی کے ساتھ بنایا گیا',
        changeToStatus: 'حیثیت کو تبدیل کریں',
        idWithDots: 'آئی ڈی: ',
        startDateWithDots: 'شروع کرنے کی تاریخ: ',
        userWithDots: 'صارف: ',
        delivryWithDots: 'ترسیل: ',
        priceKiloWattPerHourWithDots: 'کیلوواٹ فی گھنٹہ لاگت: ',
        priceCoilKiloWithDots: 'ایک کلو کوائل کی لاگت: ',
        priceProduction: 'پیداوار کی لاگت: ',
        totalPrice: 'کل قیمت: ',
        downloadInvoice: 'انوائس ڈاؤن لوڈ کریں',
        errorDuringDownloadInvoice: 'انوائس ڈاؤن لوڈ کرنے میں خرابی',
        orderStatusUpdated: 'آرڈر کی حیثیت کامیابی کے ساتھ اپ ڈیٹ کی گئی',
        error404: 'خرابی 404',
        pageNotFound: 'آپ جس صفحہ کی تلاش کر رہے ہیں وہ نہیں مل سکا۔',
        backToHome: 'صفحہ اول پر واپس جائیں',
        numberOfOrder: 'آرڈرز کی تعداد:',
        orderCancel: 'آرڈر منسوخ:',
        products: 'پروڈکٹس',
        numberOfProducts: 'پروڈکٹس کی تعداد:',
        avgProducts: 'فی آرڈر اوسط تعداد:',
        weights: 'وزن',
        totalWeight: 'کل وزن:',
        avgWeight: 'اوسط وزن:',
        time: 'وقت',
        totalTime: 'کل وقت:',
        avgTime: 'اوسط وقت:',
        price: 'لاگت',
        coilUsage: 'کوائل کا استعمال:',
        orderFor3Years: 'ماہانہ آرڈرز (3 سال):',
        productFor3Years: 'ماہانہ پروڈکٹس (3 سال):',
        january: 'جنوری',
        february: 'فروری',
        march: 'مارچ',
        april: 'اپریل',
        may: 'مئی',
        june: 'جون',
        july: 'جولائی',
        august: 'اگست',
        september: 'ستمبر',
        october: 'اکتوبر',
        november: 'نومبر',
        december: 'دسمبر',
        versionManagement: 'ورژن مینجمنٹ',
        major: 'میجر',
        minor: 'مائنر',
        fix: 'فکس',
        addVersion: 'ورژن شامل کریں',
        needFillDescriptionForThisVersion: 'براہ کرم اس ورژن کے لئے ایک تفصیل فراہم کریں',
        errorDuringCreateVersion: 'ورژن بناتے وقت خرابی',
        versionCreated: 'ورژن کامیابی کے ساتھ بنایا گیا',
        errorDuringGetCoil: 'کوائلز حاصل کرنے میں خرابی',
        errorDuringDeleteCoil: 'کوائل کو حذف کرتے وقت خرابی',
        coilDeleted: 'کوائل کامیابی کے ساتھ حذف کر دیا گیا۔',
        errorDuringCreateCoil: 'کوائل بناتے وقت خرابی',
        coilCreated: 'کوائل کامیابی کے ساتھ بنایا گیا',
        errorDuringEditCoil: 'کوائل میں ترمیم کرتے وقت خرابی',
        coilEdited: 'کوائل کامیابی کے ساتھ ترمیم کر دی گئی۔',
        errorDuringGetOrders: 'آرڈرز حاصل کرنے میں خرابی۔',
        pleaseRefreshPage: 'پروڈکٹ کو اپ ڈیٹ کرنے کے لئے براہ کرم صفحہ (f5) کو ریفریش کریں',
        errorDuringGetMessage: 'پیغامات حاصل کرنے میں خرابی',
        errorDuringGetColors: 'رنگ حاصل کرنے میں خرابی',
        errorDuringDeleteColors: 'رنگ حذف کرتے وقت خرابی',
        colorDeleted: 'رنگ کامیابی کے ساتھ حذف کر دیا گیا',
        errorDuringEditColor: 'رنگ میں ترمیم کرتے وقت خرابی',
        colorEdited: 'رنگ کامیابی کے ساتھ ترمیم کر دیا گیا',
        errorDuringGetRoles: 'کردار حاصل کرنے میں خرابی',
        errorDuringGetStats: 'اعدادوشمار حاصل کرنے میں خرابی',
        stats: 'اعدادوشمار',
        versions: 'ورژنز',
        orderDetail: 'آرڈر کی تفصیل',
        pageNotFoundShort: 'صفحہ نہیں ملا',
        admin: 'ایڈمن',
        client: 'کلائنٹ',
        language: 'زبان',
        french: 'فرانسیسی',
        english: 'انگریزی',
        hindi: 'ہندی',
        chinese: 'چینی',
        spanish: 'ہسپانوی',
        arabic: 'عربی',
        bengali: 'بنگالی',
        portuguese: 'پرتگالی',
        russian: 'روسی',
        urdu: 'اردو',
        newLanguage: 'نئی زبان',
    }
};

const t = (key) => {
    if(!localStorage.getItem('langue')) {
        localStorage.setItem('langue', 'en');
    }

    return Traduction[localStorage.getItem('langue')][key] ? Traduction[localStorage.getItem('langue')][key] : "-";
}

export { t, Traduction };