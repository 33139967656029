<template>
  <div class="flex flex-col justify-center mt-4 text-center">
    <span class="text-xl md:text-3xl">{{ stats.value.utilisateur.email }}</span>
    <span class="text-lg text-gray-400">{{ stats.value.utilisateur.livraison }}</span>
    <span class="text-lg text-gray-400">{{ t(stats.value.utilisateur.role) }}</span>
  </div>

  <div class="flex flex-col md:flex-row flex-wrap items-center justify-center mx-4 md:mx-16 mt-4">

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("ordersOnly")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("numberOfOrder")}}</span> {{ stats.value.nbCommandes }}</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("orderCancel")}}</span> {{ stats.value.nbCommandesAnnuler }}</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("products")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("numberOfProducts")}}</span> {{ stats.value.nbPiecesTotal }}</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("avgProducts")}}</span> {{ converter.round(stats.value.avgPiecesParCommande) }}</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{ t("weights") }}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("totalWeight")}}</span> {{ stats.value.nbPoidsTotal }} kg</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("avgWeight")}}</span> {{ converter.round(stats.value.avgPoids) }} g</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("time")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("totalTime")}}</span> {{ converter.minToDays(stats.value.nbTempsTotal) }}</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("avgTime")}}</span> {{ converter.minToHours(stats.value.avgTemps) }}</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("price")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("priceProduction")}}</span>{{ converter.round(stats.value.prixProductionTotal) }} €</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("totalPrice")}}</span>{{ converter.round(stats.value.prixTotal) }} €</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("leftToPay")}} : </span>{{ converter.round(stats.value.resteAPayer) }} €</p>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full flex flex-col lg:flex-row items-center justify-center mt-8 gap-8 lg:gap-0">
    <div class="w-3/4 md:w-1/2 lg:w-1/4" v-show="stats.value.couleurs.length > 0">
      <span>{{t("coilUsage")}}</span>
      <canvas id="bobineCouleur" width="100%" height="100%" class="mt-4"></canvas>
    </div>

    <div class="w-4/5 lg:w-1/2 md:pl-16 md:pr-8">
      <span>{{t("productsByPrinter")}}</span>

      <div>
        <canvas id="usagePrinter" width="100%" height="75%"></canvas>
      </div>
    </div>
  </div>


  <div class="flex flex-col lg:flex-row mx-8 md:mx-16 mt-8 mb-16 items-center">
    <div class="w-full lg:w-1/2 md:pl-16 md:pr-8">
      <span>{{t("orderFor3Years")}}</span>

      <div>
        <canvas id="commandesChart" width="100%" height="75%"></canvas>
      </div>
    </div>

    <div class="w-full lg:w-1/2 md:pr-16 md:pl-8 mt-8 md:mt-0">
      <span>{{t("productFor3Years")}}</span>

      <div>
        <canvas id="produitsChart" width="100%" height="75%"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>

import {defineProps, onMounted, reactive, watch} from "vue";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import converter from "../utils/Converter";
import {ArcElement, Chart, Legend, PieController, Tooltip, registerables } from "chart.js";
import {t} from "../utils/Traduction";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import { utils } from "@/utils/other";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout();
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

Chart.register(PieController, ArcElement, Tooltip, Legend, ...registerables);

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});


let stats = reactive({value: {
    utilisateur: {email: ''},
    nbPiecesTotal: 0,
    nbCommandes: 0,
    nbCommandesAnnuler: 0,
    avgPiecesParCommande: 0,
    nbPoidsTotal: 0,
    avgPoids: 0,
    nbTempsTotal: 0,
    avgTemps: 0,
    prixProductionTotal: 0,
    prixTotal: 0,
    couleurs: [],
    resteAPayer: 0,
    nbProduitParImprimante: [],
  }});

utilisateurStore.getStatsByUser(props.uuid).then((response) => {
  response.nbPoidsTotal = response.nbPoidsTotal / 1000;
  response.couleurs = response.couleurs.map((couleur) => {
    couleur.total = couleur.total / 1000;
    return couleur;
  });

  stats.value = response;
});

onMounted(() => {

  watch(stats, () => {
    // chart pie pour les couleurs
    let data = {
      datasets: [{
        data: stats.value.couleurs.map((couleur) => couleur.total),
        backgroundColor: stats.value.couleurs.map((couleur) => couleur.code)
      }],
      labels:stats.value.couleurs.map((couleur) => couleur.libelle)
    };

    let config = {
      type: 'pie',
      data: data,
      options: {
        responsive: true, plugins: {legend: {display: false}}},
    };

    new Chart(
        document.getElementById('bobineCouleur'),
        config
    );

    const datasetImprimante = {
      label: t("productsByPrinter"),
      data: stats.value.nbProduitParImprimante.map((imprimante) => imprimante.total),
      backgroundColor: stats.value.nbProduitParImprimante.map(() => utils.getRandomColorHexa())
    };

    // chart horizontal bar pour les imprimantes
    let configImprimante = {
      type: 'bar',
      data: {
        labels: stats.value.nbProduitParImprimante.map((imprimante) => imprimante.libelle),
        datasets: [datasetImprimante]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        indexAxis: "y",
        scales: {
          x: {
            ticks: {
              min: 0,
              beginAtZero: true,
            },
            max: Math.max(...stats.value.nbProduitParImprimante.map((imprimante) => imprimante.total)) + 1,
          }
        }
      }
    };

    new Chart(
        document.getElementById('usagePrinter'),
        configImprimante
    );

    // fonction transformation dict en chat dataset
    const chartData = (dict) => {
      const labels = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
        t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];

      const currentYear = new Date().getFullYear();
      const yearsLabels = [currentYear - 2, currentYear - 1, currentYear];

      const datasets = [
        {label: yearsLabels[0], data: [], backgroundColor: 'rgba(255, 99, 132, 0.5)'},
        {label: yearsLabels[1], data: [], backgroundColor: 'rgba(54, 162, 235, 0.5)'},
        {label: yearsLabels[2], data: [], backgroundColor: 'rgba(255, 206, 86, 0.5)'},
      ];

      labels.forEach((label, index) => {
        const month = index + 1;
        datasets.forEach(dataset => {
          const year = dataset.label;
          const monthKey = `${month < 10 ? '0' + month : month}-${year}`;
          const value = dict[monthKey] || 0;
          dataset.data.push(value > 0 ? value: 0.1);
        });
      });
      return {labels, datasets};
    }

    // chart bar pour les commandes
    const ctx = document.getElementById('produitsChart').getContext('2d');
    new Chart(ctx, {
      type: 'bar',
      data: chartData(stats.value.nbrProduitParMois),
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: Math.max(...Object.values(stats.value.nbrProduitParMois)) + 1,
          }
        }
      }
    });

    // chart bar pour les produits
    const ctxProduit = document.getElementById('commandesChart').getContext('2d');
    new Chart(ctxProduit, {
      type: 'bar',
      data: chartData(stats.value.dateCommandeParMois),
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: Math.max(...Object.values(stats.value.dateCommandeParMois)) + 1,
          }
        }
      }
    });
  });
});



</script>

<style scoped>

</style>